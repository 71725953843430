import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface InfoCardSliderSectionProps {
    componentData: {
        infoCards: Array<{
            media: string;
            title: string;
            subtitle: string;
            url: string;
        }>;
        componentName: string;
    };
}

const InfoCardSliderSection: React.FC<InfoCardSliderSectionProps> = ({ componentData }) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam || 'homepage';

    useEffect(() => {
        if (inView) {
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,
                componentName: componentData.componentName,
                visitorId: sessionStorage.getItem('visitorId'),
                componentCount: componentData.infoCards.length,
            })
            .then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            })
            .catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView]);

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: -300,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                left: 300,
                behavior: 'smooth',
            });
        }
    };

    const renderMedia = (media: string) => {
        if (!media) {
            return <p>Dosya yüklenemedi veya geçerli bir medya türü değil.</p>;
        }

        const fileExtension = media.split('.').pop()?.toLowerCase();

        if (!fileExtension) {
            return <p>Geçersiz dosya uzantısı. URL: {media}</p>;
        }

        if (['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'].includes(fileExtension)) {
            return (
                <img
                    src={media}
                    alt="Media"
                    className="object-contain"
                    style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                />
            );
        }

        if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
            return (
                <video
                    className="object-contain"
                    style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                    controls
                >
                    <source src={media} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        }

        return <p>Desteklenmeyen medya türü veya geçersiz dosya formatı. URL: {media}</p>;
    };

    return (
        <div ref={ref} className="relative w-full h-[37.5vh] flex items-center justify-center bg-white-100"  style={{ marginTop: '0px', marginBottom: '0px', overflow: 'hidden', maxWidth:'90%' , marginLeft:'5%'}}>
            <button
                className="absolute left-4 z-10 p-2 rounded-full bg-opacity-0 transition-all duration-300 hover:bg-gray-100 hover:bg-opacity-100 flex items-center justify-center"
                onClick={scrollLeft}
                style={{ border: 'none', boxShadow: 'none', left: '10px' }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
            </button>

            <motion.div
                id="slider"
                ref={sliderRef}
                className="slider-container flex justify-start items-center overflow-x-auto scrollbar-hide p-3 w-[90%] mx-auto scroll-smooth rounded-3xl"
                style={{ 
                    gap: '18.75px', 
                    scrollSnapType: 'x mandatory', 
                    scrollPaddingLeft: '15px',  
                    scrollSnapAlign: 'start'    
                }}
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, ease: 'easeOut' }}
            >
                {componentData.infoCards.map((card, index) => (
                    <motion.a
                        key={index}
                        href={card.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="min-w-[240px] sm:min-w-[271.34px] max-w-[300px] h-[195px] p-3 bg-white rounded-2xl flex-shrink-0 border border-gray-200 shadow-md snap-center flex items-center"
                        whileHover={{ scale: 1.05, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}
                        transition={{ duration: 0.3 }}
                        style={{ scrollSnapAlign: 'start' }}
                    >
                        <div className="flex flex-col justify-center w-1/2 pr-3">
                            <h3
                                className="text-left text-md sm:text-xl font-semibold mb-1"
                                style={{ fontFamily: 'Radhanji, sans-serif' }}
                            >
                                {card.title}
                            </h3>
                            <p
                                className="text-left text-sm sm:text-md text-gray-500"
                                style={{ fontFamily: 'Radhanji, sans-serif' }}
                            >
                                {card.subtitle}
                            </p>
                        </div>

                        <div className="w-1/2 h-full flex justify-center items-center">
                            {card.media && renderMedia(card.media)}
                        </div>
                    </motion.a>
                ))}
            </motion.div>

            <button
                className="absolute right-4 z-10 p-2 rounded-full bg-opacity-0 transition-all duration-300 hover:bg-gray-100 hover:bg-opacity-100 flex items-center justify-center"
                onClick={scrollRight}
                style={{ border: 'none', boxShadow: 'none', right: '10px' }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
            </button>
        </div>
    );
};

export default InfoCardSliderSection;
