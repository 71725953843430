import React from 'react';
import { Link } from 'react-router-dom';
import damiseLogo from '../assets/images/Damise.png'; // DAMISE logosu
import notFoundImage from '../assets/images/404ERROR.png'; // 404 sayfa bulunamadı ikonu

const NotFoundPage: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-50 relative">
            {/* DAMISE logosu sol üstte */}
            <img
                src={damiseLogo}
                alt="DAMISE Logo"
                className="absolute top-6 left-6 w-48 h-auto" // Sol üstte ve boyutlandırılmış
            />

            {/* Sayfa bulunamadı metni */}
            <p className="text-2xl mt-20 mb-8">Sayfa Bulunamadı</p>

            {/* 404 İkonu */}
            <div className="flex flex-col items-center justify-center">
                <img
                    src={notFoundImage}
                    alt="404 Not Found"
                    className="mb-4 w-64 h-auto" // 404 resmi, sayfa bulunamadı
                />
            </div>

            {/* Ana sayfaya dön butonu */}
            <Link to="/" className="mt-6 bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-all">
                Ana Sayfaya Dön
            </Link>
        </div>
    );
};

export default NotFoundPage;