import React, { useEffect, useState } from 'react';

interface LaunchAnnouncementProps {
    startDate: Date;
    endDate: Date;
}

const LaunchAnnouncement: React.FC<LaunchAnnouncementProps> = ({ startDate, endDate }) => {
    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [language, setLanguage] = useState<string>(document.documentElement.lang || 'tr'); // Default language

    // Check language change function
    const checkLanguageChange = () => {
        const lang = document.documentElement.lang || 'tr';
        if (lang !== language) {
            setLanguage(lang); // Update language
        }
    };

    // useEffect to check language change
    useEffect(() => {
        const intervalId = setInterval(checkLanguageChange, 1000); // Check language change every second

        return () => clearInterval(intervalId); // Cleanup
    }, [language]);

    useEffect(() => {
        const today = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);

        let message = '';

        // Generate message based on date and language
        if (today < start) {
            message = language === 'en'
                ? `🚀 This launch will be live on ${start.toLocaleDateString()}!`
                : `🚀 Bu lansman ${start.toLocaleDateString()} tarihinde yayında!`;
        } else if (today > end) {
            message = language === 'en'
                ? `⏰ The launch took place between ${start.toLocaleDateString()} and ${end.toLocaleDateString()}.`
                : `⏰ Lansman ${start.toLocaleDateString()} - ${end.toLocaleDateString()} tarihleri arasında gerçekleşti.`;
        }

        setCurrentMessage(message);

        // Set interval for visibility toggle
        const intervalId = setInterval(() => {
            setIsVisible((prev) => !prev);
        }, 3000);

        return () => clearInterval(intervalId); // Cleanup
    }, [startDate, endDate, language]); // Track language change

    return (
        <div className="fixed top-32 left-0 w-full z-50 flex justify-center items-center overflow-hidden sm:top-36">
            <div
                className={`transition-opacity duration-1000 ease-in-out border border-gray-300 rounded-lg bg-red-800 text-white px-4 py-2 shadow-2xl transform-gpu ${isVisible ? 'opacity-100' : 'opacity-0'
                    } animate-bounce sm:px-6 sm:py-3 max-w-full sm:max-w-lg`}
            >
                <p className="text-xs sm:text-base font-semibold tracking-wide whitespace-normal text-center">
                    {currentMessage}
                </p>
            </div>
        </div>
    );
};

export default LaunchAnnouncement;
