import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom'; // useLocation import edildi
import Navbar from '../components/Navbar';
import lansmanLoginImage from '../assets/images/LansmanLogin.png'; // Görseli import ediyoruz

interface DamiseUserResponse {
    token: string;
    _id: string; // Backend'den visitorId alıyoruz...
}

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [placeholders, setPlaceholders] = useState({
        email: 'E-posta adresinizi giriniz',
        password: 'Şifrenizi giriniz',
        remember: 'Beni Hatırla',
        loginButton: 'Giriş Yap',
        forgotPassword: 'Şifremi Unuttum',
        signUp: 'Kayıt ol',
    });

    const navigate = useNavigate();
    const location = useLocation(); // Bir önceki sayfanın konumunu alır

    // Dil değişikliklerini izlemek için MutationObserver kullanımı
    useEffect(() => {
        const observer = new MutationObserver(() => {
            const lang = document.documentElement.lang;

            // Eğer dil Türkçe ise
            if (lang === 'tr') {
                setPlaceholders({
                    email: 'E-posta adresinizi giriniz',
                    password: 'Şifrenizi giriniz',
                    remember: 'Beni Hatırla',
                    loginButton: 'Giriş Yap',
                    forgotPassword: 'Şifremi Unuttum',
                    signUp: 'Kayıt ol',
                });
            } else {
                // Eğer dil İngilizce ise
                setPlaceholders({
                    email: 'Enter your email',
                    password: 'Enter your password',
                    remember: 'Remember Me',
                    loginButton: 'Login',
                    forgotPassword: 'Forgot Password',
                    signUp: 'Sign Up',
                });
            }
        });

        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });

        return () => observer.disconnect(); // Cleanup observer on component unmount
    }, []);

    const handleLogin = async () => {
        if (!email || !password) {
            setErrorMessage('Email ve şifre girilmelidir.');
            return;
        }

        setIsLoading(true);
        setErrorMessage('');

        try {
            const response = await axios.post<DamiseUserResponse>(
                `https://ekosistem-backend.inpdemo.com/users/login`,
                { email, password },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            const { token, _id } = response.data;
            console.log('Login başarılı: ', token, _id);
            sessionStorage.setItem('visitorId', _id);
            navigate(location.state?.from || -1);
        } catch (error: any) {
            if (error.response) {
                console.error('Sunucu hatası:', error.response.data); // Sunucudan gelen hata burada gösterilecek
                setErrorMessage(`Sunucu hatası: ${error.response.data.message || 'Bilinmeyen hata'}`);
            } else if (error.request) {
                console.error('İstek gönderilemedi:', error.request); // İstek sunucuya ulaşmadıysa
                setErrorMessage('Sunucuya erişim sağlanamadı.');
            } else {
                console.error('Diğer hata:', error.message); // Başka bir hata oluştuysa
                setErrorMessage('Bir hata oluştu: ' + error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <Navbar />
            <div className="flex bg-white rounded-lg shadow-lg overflow-hidden max-w-4xl w-full">
                {/* Sol taraf, görsel */}
                <div className="hidden md:block w-1/2 bg-gray-100">
                    <img
                        src={lansmanLoginImage}
                        alt="Lansman Login"
                        className="w-full h-full object-cover"
                    />
                </div>

                {/* Sağ taraf, giriş formu */}
                <div className="w-full md:w-1/2 p-8">
                    <h2 className="text-3xl font-bold text-gray-800 mb-6">{placeholders.loginButton}</h2>

                    {/* Email input */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">* {placeholders.email}</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="email"
                                className="form-input block w-full px-4 py-3 border border-gray-300 rounded-md"
                                placeholder={placeholders.email}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <FaEnvelope className="text-gray-400" />
                            </div>
                        </div>
                    </div>

                    {/* Password input */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">* {placeholders.password}</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                                type="password"
                                className="form-input block w-full px-4 py-3 border border-gray-300 rounded-md"
                                placeholder={placeholders.password}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <FaLock className="text-gray-400" />
                            </div>
                        </div>
                    </div>

                    {/* Beni Hatırla */}
                    <div className="mb-4 flex items-center">
                        <input type="checkbox" id="remember" className="h-4 w-4 text-red-900 focus:ring-red-800 border-gray-300 rounded" />
                        <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">
                            {placeholders.remember}
                        </label>
                    </div>

                    {/* Error message */}
                    {errorMessage && (
                        <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
                    )}

                    {/* Login button */}
                    <button
                        onClick={handleLogin}
                        disabled={isLoading}
                        className={`w-full py-3 text-white rounded-lg bg-red-900 hover:bg-red-700 transition duration-300 ${isLoading && 'opacity-50 cursor-not-allowed'}`}
                    >
                        {isLoading ? 'Giriş Yapılıyor...' : placeholders.loginButton}
                    </button>

                    {/* Alt Bağlantılar */}
                    <div className="mt-4 flex justify-between">
                        <a href="https://damise.com/tr/sifremi-unuttum" className="text-sm text-red-900 hover:underline">
                            {placeholders.forgotPassword}
                        </a>
                        <a href="https://damise.com/tr/uyelik" className="text-sm text-blue-500 hover:underline">
                            {placeholders.signUp}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
