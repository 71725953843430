import React, { useEffect, useRef } from 'react';

interface PopupMembersENProps {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;
}

const PopupMembersEN: React.FC<PopupMembersENProps> = ({ isOpen, onClose, onAccept }) => {
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const handleAccept = () => {
        onAccept();
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div
                ref={popupRef}
                className="bg-white p-6 rounded-lg max-w-lg w-full relative max-h-[80vh] overflow-y-auto 
                          md:max-w-lg md:p-6 sm:max-w-sm sm:p-4 sm:text-sm"
            >
                <h2 className="text-xl font-semibold mb-4 sm:text-lg">MEMBERSHIP INFORMATION AND CORPORATE MEMBERSHIP AGREEMENT</h2>
                <p className="mb-4">
                    Please read these 'Site Terms of Use' carefully before using our site.
                </p>
                <p className="mb-4">
                    Users and members of this site are deemed to have accepted the following terms:
                </p>
                <p className="mb-4">
                    The web pages of our site and all related pages ('SITE') belong to DAMISE Bilişim Hizmetleri A.Ş at www.damise.com and are operated by it. By using the services offered on the site, you (‘User/Member’) agree to be subject to the following terms, you declare that you are over the age of 18, have the legal competence and authority to sign contracts, and have read, understood, and accepted the terms of this agreement.
                </p>
                <p className="mb-4">
                    DAMISE reserves the right to change these terms of use at any time. However, changes will be posted on the SITE and will be effective from that date onwards. Every real and legal person benefiting from the SITE services and accessing the SITE is deemed to have accepted any changes to the terms of use made by DAMISE. DAMISE reserves the right to change any information, forms, and content on this SITE at any time.
                </p>
                <p className="mb-4">
                    This agreement is indefinite and binds the parties to the rights and obligations related to the site, and when the parties accept/confirm this agreement online or in writing, they declare and undertake that they will fully, accurately, and timely fulfill their obligations as required by this agreement.
                </p>
                <p className="mb-4">
                    <strong>1- SCOPE OF SERVICES</strong>
                </p>
                <p className="mb-4">
                    DAMISE Ecosystem is a platform where real or legal persons providing products and services can showcase their products and services, view general information about other companies, and communicate via messaging within the system.
                </p>
                <p className="mb-4">
                    It is Turkey’s first digital platform established to support national and domestic organizations operating in the aviation, satellite, space, defense industry, and maritime sectors, to strengthen their presence nationally and internationally, and to gather all the topics each player in the sector may need in one place.
                </p>
                <p className="mb-4">
                    The services provided by DAMISE on the SITE are a platform that can be used by users who have become members of www.damise.com and paid the membership fee after logging in.
                </p>
                <p className="mb-4">
                    Individuals can become members of this platform with their corporate email addresses, represent their companies/businesses in the system as a company administrator, and create corporate profiles.
                </p>
                <p className="mb-4">
                    DAMISE is entirely free to determine the scope and nature of the services it will provide through the SITE, and the changes it makes to the services will be considered effective once they are published on the SITE.
                </p>
                <p className="mb-4">
                    In order to benefit from the services to be offered within the SITE, users must meet the characteristics specified by DAMISE and outlined in the relevant section of the SITE. DAMISE is entirely free to determine these characteristics, and the changes it makes will be considered effective once they are published on the SITE.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">2- MEMBERSHIP TYPES AND PRICING</h2>
                <p className="mb-4">
                    Members can choose one of the following membership types. Membership fees are paid upon signing the membership agreement. The membership fee will be updated by DAMISE for each term.
                </p>
                <p className="mb-4">
                    <strong>A - Freemium Membership - Valid for 12 Months for 1 User - Free</strong>
                </p>
                <p className="mb-4">
                    Freemium package member companies;
                </p>
                <ul className="mb-4 list-disc pl-5">
                    <li>Showcase Products / Services</li>
                    <li>Detailed Product/Service Search and View</li>
                    <li>View Sectoral Content for News, Blogs, Announcements, Publications, and Events</li>
                    <li>Limited Access to Statistical Data on the Admin Panel</li>
                    <li>Participation in Surveys Provided in the Supplier Management System</li>
                    <li>Define 1 Company Manager</li>
                    <li>Stay Updated with E-Newsletter Membership</li>
                    <li>Respond to Messages from the Platform</li>
                    <li>Receive Monthly Platform Notifications</li>
                </ul>

                <p className="mb-4">
                    <strong>B - Standard Membership - Valid for 12 Months for 3 Users - 1200 USD</strong>
                </p>
                <p className="mb-4">
                    Standard package member companies;
                </p>
                <ul className="mb-4 list-disc pl-5">
                    <li>Showcase Products / Services</li>
                    <li>Detailed Product/Service Search and View</li>
                    <li>View Sectoral Content for News, Blogs, Announcements, Publications, and Events</li>
                    <li>Access to Statistical Data in the Admin Panel</li>
                    <li>Participation in Surveys Provided in the Supplier Management System</li>
                    <li>Define 3 Company Managers</li>
                    <li>Stay Updated with E-Newsletter Membership</li>
                    <li>One-to-one Messaging on the Platform</li>
                    <li>Receive Weekly Notifications about the Company, Products/Services*</li>
                    <li>View the List of International Sectoral Tenders</li>
                    <li>Access to Free Sectoral Reports</li>
                    <li>View Event Calendar</li>
                    <li>Add Companies, Products/Services to Favorites</li>
                    <li>Visitor Participation in Events Presented in Digital Showcase</li>
                    <li>View Number of Companies Adding to Favorites*</li>
                </ul>

                <p className="mb-4">
                    <strong>C - Professional Membership - Valid for 12 Months for 5 Users - 2400 USD</strong>
                </p>
                <p className="mb-4">
                    Professional package member companies;
                </p>
                <ul className="mb-4 list-disc pl-5">
                    <li>Showcase Products / Services</li>
                    <li>Detailed Product/Service Search and View</li>
                    <li>View Sectoral Content for News, Blogs, Announcements, Publications, and Events</li>
                    <li>Access to Statistical Data and Data Analytics in the Admin Panel</li>
                    <li>Participation in Surveys Provided in the Supplier Management System</li>
                    <li>Define 5 Company Managers</li>
                    <li>Stay Updated with E-Newsletter Membership</li>
                    <li>One-to-one Messaging on the Platform</li>
                    <li>Receive Daily Notifications about the Company, Products/Services*</li>
                    <li>Access to International Sectoral Tenders from One Point</li>
                    <li>Access to Free Sectoral Reports</li>
                    <li>View Event Calendar and Create Events</li>
                    <li>Add Companies, Products/Services to Favorites</li>
                    <li>Visitor Participation in Events Presented in Digital Showcase</li>
                    <li>View the List of Companies Adding to Favorites*</li>
                    <li>Access to Discounted Paid Sectoral Reports</li>
                    <li>Right to Publish 1 Call in the Supplier Management System</li>
                    <li>Publish Blog, Announcement, and Event Content</li>
                    <li>Highlight Company, Product/Service Cards</li>
                    <li>Track the Validity of Certificates and Receive Reminder Notifications</li>
                    <li>Hold a Certified Company Badge</li>
                </ul>

                <p className="mb-4">
                    <strong>D - Business Membership - Valid for 12 Months for 10 Users - 4800 USD</strong>
                </p>
                <p className="mb-4">
                    Business package member companies;
                </p>
                <ul className="mb-4 list-disc pl-5">
                    <li>Showcase Products / Services</li>
                    <li>Detailed Product/Service Search and View</li>
                    <li>View Sectoral Content for News, Blogs, Announcements, Publications, and Events</li>
                    <li>Access to Statistical Data, Data Analytics, and Visitor Behavior Analysis in the Admin Panel</li>
                    <li>Participation in Surveys Provided in the Supplier Management System</li>
                    <li>Define 10 Company Managers</li>
                    <li>Stay Updated with E-Newsletter Membership</li>
                    <li>One-to-one Messaging on the Platform</li>
                    <li>Receive Daily/Instant Notifications about the Company, Products/Services*</li>
                    <li>Access to International Sectoral Tenders from One Point and Receive Customized Notifications</li>
                    <li>Access to Free Sectoral Reports</li>
                    <li>View Event Calendar, Create Events, and Integrate with Personal Calendar</li>
                    <li>Add Companies, Products/Services to Favorites</li>
                    <li>Visitor Participation in Events Presented in Digital Showcase</li>
                    <li>View Detailed Information About Companies Adding to Favorites*</li>
                    <li>Access to Discounted Paid Sectoral Reports</li>
                    <li>Right to Publish 3 Calls in the Supplier Management System</li>
                    <li>Publish Blog, Announcement, and Event Content</li>
                    <li>Highlight Company, Product/Service Cards</li>
                    <li>Track the Validity of Certificates and Receive Reminder Notifications</li>
                    <li>Hold a Certified Company Badge</li>
                    <li>Publish Custom Surveys for Companies</li>
                    <li>Publish National and International Tenders</li>
                    <li>Announcement on Digital Platforms</li>
                    <li>Dedicated Customer Representative</li>
                    <li>Data Transfer*</li>
                </ul>

                <p className="mb-4">
                    <strong>Note:</strong>
                </p>
                <p className="mb-4">
                    - Prices are for a 1-year membership fee.
                    <br />
                    - All prices include 20% VAT.
                    <br />
                    - Prices in USD will be converted to Turkish Lira based on the daily exchange rate set by the Central Bank of the Republic of Turkey.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">3- RIGHTS AND RESPONSIBILITIES</h2>
                <p className="mb-4">
                    - DAMISE reserves the right to change prices and the products and services offered at any time.
                </p>
                <p className="mb-4">
                    - DAMISE undertakes that the user will benefit from the services provided under the contract, except for technical failures.
                </p>
                <p className="mb-4">
                    - The USER accepts in advance that they will not reverse engineer the site or attempt to find or obtain its source code, and that otherwise, they will be responsible for any damages that may occur to third parties and legal and criminal actions will be taken.
                </p>
                <p className="mb-4">
                    - The USER agrees that the personal and other information they provide while becoming a member of the site is correct and up-to-date under the law. The USER is responsible for any damages that may arise from incorrect or incomplete information provided, and in case of incorrect information, DAMISE reserves the right to terminate the USER's membership unilaterally without any notice or warning.
                </p>
                <p className="mb-4">
                    - The USER may not share the password provided by DAMISE with any other individuals or organizations. The responsibility for the use of the password belongs solely to the USER, and any claims or demands against DAMISE arising from unauthorized use will be the responsibility of the USER.
                </p>
                <p className="mb-4">
                    - DAMISE reserves the right to collect certain information, such as the name of the Internet service provider used to access the site, the Internet Protocol (IP) address, access times, and the pages visited, in order to improve and develop the site, and to comply with legal regulations.
                </p>
                <p className="mb-4">
                    - The USER agrees that they will not produce or share content that is contrary to public morality, law, third-party rights, misleading, offensive, obscene, pornographic, infringing on personal rights, or promoting illegal activities while using the site. Otherwise, they accept that legal and criminal actions may be taken, and DAMISE may suspend or terminate such accounts.
                </p>
                <p className="mb-4">
                    - Relationships between the site's members or third parties are the responsibility of the members.
                </p>
                <p className="mb-4">
                    - Members, based on the membership package they purchased, can produce content (news, blogs, events, announcements, reports, publications, surveys, etc.) for their organizations. DAMISE reserves the right to approve or reject this content.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">4- INTELLECTUAL PROPERTY RIGHTS</h2>
                <p className="mb-4">
                    The title, business name, brand, patent, logo, design, information, and methods on this site, whether registered or not, are protected by national and international law and belong to DAMISE or the relevant party.
                </p>
                <p className="mb-4">
                    The information on this site may not be reproduced, published, copied, or transferred without permission. Violation of this provision will result in legal and financial consequences.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">5- PRIVACY AND SECURITY TERMS</h2>
                <h3 className="text-lg font-semibold mb-4 sm:text-md">5-1. Privacy and Security of Membership Information</h3>
                <p className="mb-4">
                    All information entered during membership registration will not be shared with third parties. DAMISE may send campaign, product, and promotional information to its members. Members can choose to receive or opt-out of these communications.
                </p>

                <h3 className="text-lg font-semibold mb-4 sm:text-md">5-2. Credit Card Security</h3>
                <p className="mb-4">
                    DAMISE ensures that credit card information is not stored and uses secure communication channels.
                </p>

                <h3 className="text-lg font-semibold mb-4 sm:text-md">5-3. Email Security</h3>
                <p className="mb-4">
                    Credit card numbers or passwords should never be sent via email. DAMISE cannot guarantee the security of email communications.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">6- PERSONAL DATA</h2>
                <p className="mb-4">
                    To provide better services to our Members, facilitate communication with you, provide you with information regarding products, services, campaigns, and promotions, better understand your preferences, conduct trend and statistical analysis, and enhance the security of the Site, and in compliance with the relevant legal obligations derived from the Law No. 5651 on the Regulation of Publications on the Internet and Combating Crimes Committed Through These Publications, Law No. 6563 on the Regulation of Electronic Commerce, Turkish Penal Code No. 5237, and the Law No. 6698 on the Protection of Personal Data and its secondary legislation, we process certain personal data that directly or indirectly identifies you, such as your name, surname, email address, phone number, Turkish ID number, tax identification number, billing and delivery addresses, including information shared via social media accounts when you connect through those channels.
                </p>
                <p className="mb-4">
                    In addition to this data, non-personal statistical data such as the frequency and times of site visits, the times of orders, IP addresses at the time of the order, statistics of the product pages visited and the ordered products, are also collected and processed to analyze and understand visitor behaviors and preferences.
                </p>
                <p className="mb-4">
                    We process and store these personal data in accordance with the purposes and scope defined in this Membership Agreement and ensure that they are not used outside of these purposes while taking the necessary information security measures. We use the personal data collected in our data bank for periodical campaign activities, special promotional activities for customer profiles, and for customer classification studies aimed at preventing unwanted emails, based on your explicit consent, and in compliance with the relevant legal regulations.
                </p>
                <p className="mb-4">
                    When you use a credit card to pay for the membership/service fee, you agree to share your credit card number, expiration date, CVV2 code, and similar information with banks. However, credit card and/or bank card information used during the order process is not stored in our systems in any way.
                </p>
                <p className="mb-4">
                    DAMISE reserves the right to retain any records of terminated membership accounts by anonymizing them. Once anonymized, data lose their capacity to identify a person and thus are no longer considered personal data. The Member cannot claim any rights or compensation regarding anonymized records.
                </p>
                <p className="mb-4">
                    We may share your personal data with third parties (such as shipping, delivery, call center, database services companies) with whom we collaborate to offer our services, and with government authorities as required by legal obligations. DAMISE may also share your personal data and browsing information such as traffic data with public authorities authorized to request this information in the context of your visits or membership to the Site, for security reasons or for compliance with DAMISE's legal obligations. However, we will not use your personal data for commercial purposes or share them with third parties outside the activities listed above and the obligations required by the relevant legislation.
                </p>
                <p className="mb-4">
                    DAMISE is legally obligated to retain the information of the Member for the legally required periods. Furthermore, the Member always has the right to be informed about their personal data, to access these data, to request their correction or deletion, and to inquire whether they have been used in line with the purposes.
                </p>
                <p className="mb-4">
                    For more information on the rights of the data owner and the application channels for exercising these rights, you can refer to the Clarification Text for Product and Service Buyers available on our website.
                </p>
                <p className="mb-4">
                    You can always update your membership and communication preferences by logging into the system.
                </p>
                <p className="mb-4">
                    In accordance with Law No. 6563 on the Regulation of Electronic Commerce, records indicating that consent has been revoked will be retained for 1 year from the date of revocation, while the content of commercial electronic messages and all other records related to the transmission will be retained for 3 years to be submitted to the relevant ministry upon request. After the expiration of these periods, your personal data will be deleted, destroyed, or anonymized by our company, or upon your request.
                </p>
                <p className="mb-4">
                    DAMISE takes the protection of personal data very seriously. DAMISE implements the necessary measures to protect personal data against unauthorized access, loss, misuse, disclosure, alteration, or destruction.
                </p>
                <p className="mb-4">
                    DAMISE undertakes to keep your personal data confidential and to take the necessary physical, technical, and administrative measures to ensure confidentiality and security and to show the necessary care. In the event that personal data is compromised or accessed by third parties due to attacks on the Site or system despite DAMISE taking the necessary information security measures, DAMISE will immediately notify you and the Personal Data Protection Board and will take the necessary actions.
                </p>
                <p className="mb-4">
                    For the security of the information and transactions provided by users, the necessary measures are taken by our company or the relevant organizations in the systems and internet infrastructure according to the nature of the information and transaction. In your interactions with our Site, all credit card transactions and approvals are carried out online between you and the relevant Bank or similar Card Organizations independently of our company (credit card "password" information is not seen or recorded by our company). The information entered for user registration, product/service purchase, and information updates, as well as sensitive confidential information regarding credit cards and bank cards, cannot be viewed by other internet users. Your information may be disclosed to relevant institutions within the framework of our legal obligations.
                </p>
                <p className="mb-4">
                    In addition, the personal information provided by the members, which will be subsequently provided in various ways and communication information obtained within the scope of consent/laws, as well as any information related to product-service purchases, is processed, stored, and archived with necessary security measures to ensure their confidentiality. They can also be used and transferred in compliance with the legal principles and personal data retention-destruction policies of the data controller company, for the purposes of legal reasons related to consumer rights, customer-user services, and related legal obligations. The company processes and transfers personal data only when legally necessary for the establishment or execution of contracts, or for the exercise or protection of legal rights, or when the legitimate interests of the data controller/processor, as specified in the laws, require it. These personal data may be processed and transferred (in Turkey and abroad) in written/magnetic archives, stored, preserved, made available, used, updated, altered, merged, re-organized, classified, disclosed, and transferred for the purposes mentioned above, in accordance with the law.
                </p>
                <p className="mb-4">
                    With your explicit consent, we may send you promotional, advertising, communication, sales, and marketing messages, as well as notifications, product-service promotions, and electronic commercial communications (via SMS/short messages, instant notifications, automatic calls, email, fax, Bluetooth, social media, and online communication networks, and other electronic communication tools) via the communication channels you have provided to us.
                </p>
                <p className="mb-4">
                    You can always and without providing any reason stop receiving such commercial communications by contacting our company or using the opt-out methods mentioned in our communications, or by using the official system established for this purpose. Once your opt-out is processed, commercial electronic communications will cease within the legally permitted maximum period.
                </p>
                <p className="mb-4">
                    The updates you make to your personal information (including contact details) will also apply to the permissions you have given for the processing of your personal data and electronic commercial communications. Similarly, members who change their communication preferences or unsubscribe from commercial electronic communications will continue to receive them if they opt-in again or if they update their preferences on their membership page.
                </p>
                <p className="mb-4">
                    By providing the information requested in the Membership Information on our Site and approving this Agreement, the User confirms that they have previously seen and reviewed the information regarding personal data processing and membership-customer services on our Site (and/or mobile application).
               </p>
               <p className="mb-4">
                    The User declares that they have read, understood, and will fully and timely comply with all the terms and obligations specified in this Agreement, including but not limited to the right to stop receiving commercial communications at any time without providing any reason.
               </p>
               <p className="mb-4">
                    Commercial transactions that result from product/service purchases based on the communications, notifications, promotions, and advertisements received by the members will be subject to a separate and legally conducted consumer contract between the parties. This consumer contract will be applicable under its own terms between the respective parties. For your purchases from our Site, the order pre-information form and distance sales contract terms you see during each transaction will apply.
               </p>


                <h2 className="text-xl font-semibold mb-4 sm:text-lg">7- CONFIDENTIAL INFORMATION</h2>
                <p className="mb-4">
                    DAMISE will not disclose the personal information submitted by users through the site to third parties. This personal information includes any other data that identifies the User, such as their name, surname, address, phone number, mobile phone number, and email address, collectively referred to as 'Confidential Information.'
                </p>
                <p className="mb-4">
                    Confidential Information may only be disclosed to official authorities in cases where such disclosure is required by compulsory provisions of the applicable legislation or if the information is requested by official authorities in accordance with the proper legal procedures.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">8- LIMITATION OF LIABILITY</h2>
                <p className="mb-4">
                    DAMISE shall not be liable for any direct or indirect damages of any kind, whether in breach of contract, tort, or otherwise, arising out of access to the SITE, use of the SITE or the information and other data, programs, etc. on the SITE. DAMISE shall not be liable for any interruption of operation, error, omission, interruption as a result of breach of contract, tort, negligence or other causes. It is agreed that by accessing or using this SITE or other linked websites, DAMISE is released from any and all liability, damages and claims, including court and other costs, that may arise as a result of use/visit.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">9- TRANSFER OF THE AGREEMENT</h2>
                <p className="mb-4">
                    DAMISE may transfer this agreement in whole or in part at any time without notice. However, the USER may not assign this agreement or any part of it to any other party. Any such attempted assignment is void.

                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">10- NO WARRANTY</h2>
                <p className="mb-4">
                    This contractual clause shall apply to the maximum extent permitted by applicable law. The services provided by DAMISE are provided on an “as is” and “as available” basis and DAMISE makes no warranties of any kind, express or implied, statutory or otherwise, with respect to the services or the application (including all information contained therein), including all implied warranties of merchantability, fitness for a particular purpose or non-infringement.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">11- RECORD AND SECURITY</h2>
                <p className="mb-4">
                    The User is obliged to provide accurate, complete and up-to-date registration information. Otherwise, this Agreement will be deemed to be violated and the account may be closed without informing the User.
                </p>
                <p className="mb-4">
                    The User is responsible for password and account security on the site and third party sites. Otherwise, DAMISE cannot be held responsible for data loss and security breaches or damage to hardware and devices.

                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">12- FORCE MAJEURE</h2>
                <p className="mb-4">
                    If the obligations arising from the contract cannot be performed by the parties due to reasons such as natural disasters, fire, explosions, civil wars, wars, riots, popular movements, declaration of mobilization, strikes, lockouts and epidemics, infrastructure and internet failures, power outages (hereinafter collectively referred to as “Force Majeure”), which are not under the control of the parties, the parties are not responsible for this. During this period, the rights and obligations of the Parties arising from this Agreement are suspended.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">13- ENTIRE AGREEMENT AND SEVERABILITY</h2>
                <p className="mb-4">
                    If one of the terms of this contract becomes invalid in whole or in part, the rest of the contract shall remain valid.

                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">14- AMENDMENTS TO THE AGREEMENT</h2>
                <p className="mb-4">
                    DAMISE may change the services offered on the site and the terms of this agreement partially or completely at any time. Changes will be effective from the date of publication on the site. It is the Member's responsibility to follow the changes. The Member is deemed to have accepted these changes by continuing to benefit from the services offered.

                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">15- TERMINATION OF THE AGREEMENT</h2>
                <p className="mb-4">
                    Since being a member of the DAMISE platform is a special platform that provides member companies with access to sectoral data and offers the use of all kinds of information, content and data shared on the DAMISE platform to the member, the member has the opportunity to access all content by becoming a member of the DAMISE platform and since this right has been consumed once the member has been given access to the system, the membership fee is not refundable in case the member cancels his subscription.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">16- NOTICE</h2>
                <p className="mb-4">
                    All notifications to be sent to the parties regarding this Agreement will be made via DAMISE's known e-mail address and the e-mail address specified by the user in the membership form. The user agrees that the address specified while becoming a user is the valid notification address, in case of change, it will notify the other party in writing within 5 days, otherwise, notifications to this address will be deemed valid.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">17- EVIDENCE AGREEMENT</h2>
                <p className="mb-4">
                    In all disputes that may arise between the parties for transactions related to this contract, the parties' books, records and documents and computer records, e-mails, and fax records will be accepted as evidence in accordance with the Civil Procedure Law No. 6100, and the user agrees not to object to these records.

                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">18- RESOLUTION OF DISPUTES</h2>
                <p className="mb-4">
                    Istanbul (Central) Courthouse Courts and Enforcement Offices are authorized to resolve any disputes arising from the implementation or interpretation of this Agreement.
                </p>

                <h2 className="text-xl font-semibold mb-4 sm:text-lg">19- EFFECTIVENESS AND ACCEPTANCE</h2>
                <p className="mb-4">
                    This MEMBERSHIP AGREEMENT becomes effective on the date it is announced by DAMISE on the SITE. Members accept the provisions of this agreement by using the SITE. DAMISE may change the provisions of this agreement at any time, the changes shall be published on the SITE by specifying the version number and date of change and shall enter into force on the same date.
                </p>
                <p className="mb-4">
                    Those who apply for membership to DAMISE declare that they have read, understood and accepted this MEMBERSHIP AGREEMENT.
                </p>

                <div className="flex justify-end mt-6">
                    <button
                        className="bg-white border border-blue-500 text-blue-500 px-6 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition duration-300
                                   sm:px-4 sm:py-2 sm:text-sm"
                        onClick={handleAccept}
                    >
                        I Accept
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupMembersEN;
