import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/apiService'; // Axios instance'ı import edin
import LoadingSpinner from '../components/LoadingSpinner'; // Yüklenme göstergesi için bir bileşen
import PreviewPage from './PreviewPage'; // PreviewPage bileşeni

interface LaunchData {
    _id: string;
    launchName: string; // Lansman adı
    showOnHomepage: boolean;
    startDate: string;
    endDate: string;
}

const HomepagePreviewPage: React.FC = () => {
    const [launch, setLaunch] = useState<LaunchData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Yatay kaydırmayı engellemek için body'ye overflow-x: hidden ekliyoruz
        document.body.style.overflowX = 'hidden';

        // Mobil cihazlar için viewport ayarları
        const metaViewport = document.createElement('meta');
        metaViewport.name = 'viewport';
        metaViewport.content = 'width=device-width, initial-scale=1.0';
        document.head.appendChild(metaViewport);

        // Component unmount olduğunda bu stili kaldırıyoruz
        return () => {
            document.body.style.overflowX = '';
            if (metaViewport) {
                document.head.removeChild(metaViewport);
            }
        };
    }, []);

    useEffect(() => {
        const fetchHomepageLaunch = async () => {
            try {
                const response = await api.get<LaunchData[]>(`${process.env.REACT_APP_API_URL}/launch`);
                const homepageLaunches = response.data.filter((launch) => launch.showOnHomepage);

                if (homepageLaunches.length === 1) {
                    setLaunch(homepageLaunches[0]);
                } else if (homepageLaunches.length > 1) {
                    setError('Birden fazla lansman ana sayfada gösteriliyor. Sadece bir lansman olmalıdır.');
                } else {
                    setError('Ana sayfada gösterilecek bir lansman bulunamadı.');
                }
            } catch (error) {
                console.error('Lansman bilgileri alınırken bir hata oluştu:', error);
                setError('Lansman bilgileri alınırken bir hata oluştu.');
            } finally {
                setLoading(false);
            }
        };

        fetchHomepageLaunch();
    }, []);

    const handleAdminLogin = () => {
        navigate('/admin-login');
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    if (launch) {
        return (
            <div style={{ maxWidth: '100vw', overflowX: 'hidden' }}>
                <div className="flex justify-end">
                </div>
                <PreviewPage launchId={launch._id} />
            </div>
        );
    }

    return null;
};

export default HomepagePreviewPage;
