import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface Clooned3DViewerProps {
    componentData: {
        features: string;
        oid: string;
        text?: string;
        componentName: string;
    };
}

const Clooned3DViewer: React.FC<Clooned3DViewerProps> = ({ componentData }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>();
    const launchUrl = urlParam;
    const [error, setError] = useState<string | null>(null);
    const { features, oid, text = '' } = componentData;

    useEffect(() => {
        if (inView) {
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,
                visitorId: sessionStorage.getItem('visitorId'),
                componentName: componentData.componentName,
                componentCount: 1,
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl, componentData.componentName]);

    const iframeUrl = `statikdosyalar/3d-viewer.html?features=${encodeURIComponent(features)}&oid=${encodeURIComponent(oid)}`;
   
    const textVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0, transition: { duration: 1, ease: 'easeInOut' } },
    };
    console.log(features)
    console.log(oid)

    return (
        <motion.div
            ref={ref}
            className="flex flex-col md:flex-row items-stretch bg-white rounded-3xl shadow-lg w-full max-w-screen-xl mx-auto"
            initial={{ opacity: 0, y: 100 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.2, ease: 'easeOut' }}
            style={{
                height: '70vh',
                width: '100%',
            }}
        >
            <div className="bg-gray-100 w-full md:w-1/2 flex items-center justify-center rounded-t-3xl md:rounded-l-3xl md:rounded-tr-none">
                <motion.p
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={textVariants}
                    className="text-xl text-gray-800 leading-relaxed text-left break-words p-8"
                    style={{
                        maxWidth: '90%',
                        fontSize: 'clamp(18px, 4vw, 28px)',
                        lineHeight: '1.5',
                        fontFamily: 'Radhanji, sans-serif',
                    }}
                >
                    {text}
                </motion.p>
            </div>

            {/* Sağ tarafta hata veya 3D viewer gösterme */}
            <div className="w-full md:w-1/2 h-full rounded-b-3xl md:rounded-r-3xl md:rounded-bl-none overflow-hidden">
                {error ? (
                    <div className="p-8 text-center text-red-600">
                        {error}
                    </div>
                ) : (
                    <motion.iframe
                        src={iframeUrl}
                        className="w-full h-full overflow-hidden"
                        style={{
                            border: 'none',
                            width: '100%',
                            height: '100%',
                        }}
                        title="3D Model Viewer"
                        scrolling="no"
                        whileHover={{ scale: 1.03 }}
                        transition={{ duration: 0.4, ease: 'easeInOut' }}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default Clooned3DViewer;
