import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import axios from 'axios'
interface LargeScalableCardSectionProps {
    componentData: {
        media: string;
        text: string;
        componentName: string
    };
    marginTop?: number; // Dinamik margin-top
    marginBottom?: number; // Dinamik margin-bottom
}

const LargeScalableCardSection: React.FC<LargeScalableCardSectionProps> = ({ componentData, marginTop = 15, marginBottom = 15 }) => {
    const isVideo = (mediaUrl: string) => {
        return mediaUrl.endsWith('.mp4') || mediaUrl.endsWith('.webm') || mediaUrl.endsWith('.ogg');
    };
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const [scrollY, setScrollY] = useState(0);
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    const { ref, inView } = useInView({
        triggerOnce: true, // İlk açıldığında çalışır
        threshold: 0.3, // Kartın %30'u görünür olduğunda tetiklenir
    });
    useEffect(() => {
        if (inView) {
            // Backend'e post isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  // URL'den alınan launchName kullanılıyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentName: componentData.componentName,
                componentCount: 1, // Component sayısını burada belirtebilirsiniz
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl]);
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scaleFactor = Math.max(0, Math.min(1, 1 - scrollY / 500));

    const minWidth = 1042;
    const minHeight = 337;

    const maxWidth = windowSize.width;
    const maxHeight = windowSize.height;

    const width = minWidth + (maxWidth - minWidth) * scaleFactor;
    const height = minHeight + (maxHeight - minHeight) * scaleFactor;

    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `
            ::-webkit-scrollbar {
                display: none;
            }
            body {
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-x: hidden; /* Yatay kaymayı tamamen devre dışı bırak */
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return (
        <>
            <motion.div
                ref={ref}
                className="flex justify-center items-center bg-white py-10 w-full max-w-full overflow-x-hidden"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                    marginTop: `${marginTop}px`, // Dinamik margin-top
                    marginBottom: `${marginBottom}px`, // Dinamik margin-bottom..
                    transformOrigin: 'center',
                }}
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    height: `${height}px`,
                    width: `${width}px`,
                }}
                transition={{ duration: 1.7, ease: 'easeOut' }}
            >
                {componentData.media && (
                    <div className="overflow-hidden w-full h-full flex justify-center items-center">
                        {isVideo(componentData.media) ? (
                            <motion.video
                                src={componentData.media}
                                className="object-cover w-full h-full"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <motion.img
                                src={componentData.media}
                                alt="Large Scalable Card"
                                className="object-cover w-full h-full"
                            />
                        )}
                    </div>
                )}
            </motion.div>
        </>
    );
};

export default LargeScalableCardSection;
