import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useDeployDesignStore from "../store/useLaunchForComponents";
import LaunchPage from "./LaunchPage";
import LaunchAnnouncement from "../components/LaunchAnnouncement";
import VisitForm from '../components/componentmodals/VisitForm'; // Formu ekleyelim

const LaunchLayout: React.FC = () => {
    const { launchUrl } = useParams<{ launchUrl: string }>();
    const [filteredComponents, setFilteredComponents] = useState<any>([]);
    const { fetchLaunch, launch, components } = useDeployDesignStore((state: any) => state);
    const navigate = useNavigate();
    const [showVisitForm, setShowVisitForm] = useState(false); // Formun gösterim durumu
    const [visitorId, setVisitorId] = useState<string | null>(null); // visitorId'yi state'de tutuyoruz

    const fetchDatas = async () => {
        try {
            // Lansman verilerini çekiyoruz
            await fetchLaunch(launchUrl);
        } catch (error) {
            console.error("Lansman bilgileri alınırken hata oluştu:", error);
            navigate("/access-denied");
        }
    };

    useEffect(() => {
        if (launchUrl) {
            fetchDatas();
        }
        const storedVisitorId = sessionStorage.getItem('visitorId'); // Session Storage'teki visitorId kontrolü
        setVisitorId(storedVisitorId);
    }, [launchUrl, navigate]);

    useEffect(() => {
        if (launch && components) {
            // Lansmanın aktif olup olmadığını kontrol ediyoruz
            if (!launch.isActive) {
                console.log("Lansman aktif değil, erişim engellendi.");
                navigate("/access-denied"); // Eğer lansman aktif değilse erişim engelleniyor
                return;
            }

            const today = new Date();
            const startDate = new Date(launch.startDate);
            const endDate = new Date(launch.endDate);

            let sortedComponents = [];

            if (today > endDate) {
                // Lansman geçmişteyse, visitorId'ye bakmaksızın sadece fragman ve showInFragman true olan component'ları göster
                sortedComponents = components
                    .filter((component: any) => component.showInFragman)
                    .sort((a: any, b: any) => a.row - b.row);
            } else if (today >= startDate && today <= endDate) {
                // Lansman tarih aralığındaysa
                if (visitorId) {
                    // visitorId varsa tüm component'ları göster
                    sortedComponents = components.sort((a: any, b: any) => a.row - b.row);
                } else {
                    // visitorId yoksa fragman ve showInFragman true olan component'ları göster
                    sortedComponents = components
                        .filter((component: any) => component.showInFragman)
                        .sort((a: any, b: any) => a.row - b.row);
                }
            } else if (today < startDate) {
                // Gelecekteki lansmanlar için visitorId olsa bile fragman gösterilecek
                sortedComponents = components
                    .filter((component: any) => component.showInFragman)
                    .sort((a: any, b: any) => a.row - b.row);
            }

            setFilteredComponents(sortedComponents);
        }
    }, [launch, components, visitorId, navigate]);

    // Bugün tarihi kontrolü
    const today = new Date();
    const isPastOrFuture = launch && (today < new Date(launch.startDate) || today > new Date(launch.endDate));

    return (
        <>
            {launch ? (
                <>
                    {isPastOrFuture && (
                        <LaunchAnnouncement startDate={launch.startDate} endDate={launch.endDate} />
                    )}

                    {filteredComponents.length > 0 ? (
                        <LaunchPage components={filteredComponents} launchUrl={launchUrl || ''} />
                    ) : (
                        <div>Loading...</div>
                    )}

                    {/* Sağ alt köşede formun gösterilmesi */}
                    {!visitorId && launch.showOnVisitform && ( // Burada showOnVisitform kontrolü ekleniyor
                        <div className="fixed bottom-4 right-4 z-50">
                            <VisitForm
                                onClose={() => setShowVisitForm(false)}
                                onVerifySuccess={() => {
                                    // visitorId kaydedildikten sonra sayfayı yeniden yükle
                                    setVisitorId(sessionStorage.getItem('visitorId'));
                                }}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default LaunchLayout;
