import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, A11y, Autoplay } from 'swiper/modules';

interface ServiceCardSectionProps {
  componentData: {
    cards: {
      buttonText: string;
      buttonUrl: string;
      text: string;
      logoMedia: string; // Logo veya görsel medya
      backgroundMedia?: string; // Arka plan medya
      videoMedia?: string; // Video opsiyonel olarak eklendi
    }[];
  };
}

const ServiceCardSection: React.FC<ServiceCardSectionProps> = ({ componentData }) => {
  const { cards } = componentData;
  const cardCount = cards.length;

  const renderMedia = (mediaUrl: string) => {
    const fileExtension = mediaUrl.split('.').pop()?.toLowerCase();
    const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
    const videoTypes = ['mp4', 'webm', 'ogg'];
    const audioTypes = ['mp3', 'wav', 'ogg'];

    if (imageTypes.includes(fileExtension || '')) {
      return (
        <img
          src={mediaUrl}
          alt="Görsel medya"
          className="w-full h-full object-cover absolute top-0 left-0 z-0"
        />
      );
    } else if (videoTypes.includes(fileExtension || '')) {
      return (
        <video
          className="w-full h-full object-cover absolute top-0 left-0 z-0"
          autoPlay
          loop
          muted
        >
          <source src={mediaUrl} type={`video/${fileExtension}`} />
          Tarayıcınız bu videoyu desteklemiyor.
        </video>
      );
    } else if (audioTypes.includes(fileExtension || '')) {
      return (
        <audio
          className="w-full h-full object-cover absolute top-0 left-0 z-0"
          controls
        >
          <source src={mediaUrl} type={`audio/${fileExtension}`} />
          Tarayıcınız bu sesi desteklemiyor.
        </audio>
      );
    } else {
      return <p className="text-white">Desteklenmeyen medya formatı: {fileExtension}</p>;
    }
  };

  return (
    <div className="relative w-full flex items-center justify-center bg-white mt-0 mb-0">
      {cardCount <= 4 ? (
        <div className="flex justify-center space-x-6 w-full">
          {cards.map((card, index) => (
            <div
              key={index}
              className="w-[85%] md:w-[85%] lg:w-[85%] h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl mx-auto transform scale-100 hover:scale-105 transition-transform duration-300"
            >
              {card.backgroundMedia ? (
                renderMedia(card.backgroundMedia)
              ) : card.logoMedia ? (
                renderMedia(card.logoMedia)
              ) : (
                <p className="text-white">Medya bulunamadı</p>
              )}

              <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent transition-opacity duration-300 group-hover:bg-black/50">
                <p
                  className="text-base font-semibold mb-1 text-left w-full"
                  style={{
                    fontFamily: 'Radhanji, sans-serif',
                    marginBottom: '20px',
                    maxWidth: 'calc(100% - 120px)',
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                  }}
                >
                  {card.text}
                </p>
                <a
                  href={card.buttonUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30"
                  onMouseEnter={() => {
                    const swiperInstance = (document.querySelector('.swiper') as unknown as { swiper: { autoplay: { stop: () => void } } })?.swiper;
                    swiperInstance?.autoplay.stop();
                  }}
                  onMouseLeave={() => {
                    const swiperInstance = (document.querySelector('.swiper') as unknown as { swiper: { autoplay: { start: () => void } } })?.swiper;
                    swiperInstance?.autoplay.start();
                  }}
                >
                  <span
                    className="px-8 py-3 text-white bg-transparent border-2 border-white rounded-full transition-colors duration-300 group-hover:bg-white/30 group-hover:border-white/30 hover:bg-white/60 hover:border-white/60 hover:text-black"
                    style={{ fontFamily: 'Radhanji, sans-serif' }}
                  >
                    {card.buttonText}
                  </span>
                </a>
              </div>

              {card.logoMedia && (
                <div className="absolute bottom-4 right-4 w-16 h-16 z-20">
                  <img
                    src={card.logoMedia}
                    alt={`Card Logo ${index + 1}`}
                    className="w-full h-full object-contain"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <Swiper
          modules={[Pagination, A11y, Autoplay]}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: cardCount === 4 ? 4 : 3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: cardCount === 4 ? 4 : 3.3,
              spaceBetween: 40,
            },
          }}
          centeredSlides={cardCount <= 4}
          pagination={{ clickable: true }}
          autoplay={{ delay: 500, disableOnInteraction: false }}
          speed={1500}
          loop={cardCount > 4}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index}>
              <div className="w-[85%] md:w-[85%] lg:w-[85%] h-[260px] bg-white shadow-lg flex flex-col relative group overflow-hidden rounded-3xl mx-auto">
                {card.backgroundMedia ? (
                  renderMedia(card.backgroundMedia)
                ) : card.logoMedia ? (
                  renderMedia(card.logoMedia)
                ) : (
                  <p className="text-white">Medya bulunamadı</p>
                )}

                <div className="relative z-10 p-4 text-white flex flex-col justify-end h-full bg-gradient-to-t from-black via-transparent to-transparent transition-opacity duration-300 group-hover:bg-black/50">
                  <p
                    className="text-base font-semibold mb-1 text-left w-full"
                    style={{ fontFamily: 'Radhanji, sans-serif', marginBottom: '20px' }}
                  >
                    {card.text}
                  </p>
                  <a
                    href={card.buttonUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    onMouseEnter={() => {
                      const swiperInstance = (document.querySelector('.swiper') as unknown as { swiper: { autoplay: { stop: () => void } } })?.swiper;
                      swiperInstance?.autoplay.stop();
                    }}
                    onMouseLeave={() => {
                      const swiperInstance = (document.querySelector('.swiper') as unknown as { swiper: { autoplay: { start: () => void } } })?.swiper;
                      swiperInstance?.autoplay.start();
                    }}
                  >
                    <span
                      className="px-8 py-3 text-white bg-transparent border-2 border-white rounded-full group-hover:bg-white/30 group-hover:border-white/30 hover:bg-white/60 hover:border-white/60 hover:text-black"
                      style={{ fontFamily: 'Radhanji, sans-serif' }}
                    >
                      {card.buttonText}
                    </span>
                  </a>
                </div>

                {card.logoMedia && (
                  <div className="absolute bottom-4 right-4 w-16 h-16 z-20">
                    <img
                      src={card.logoMedia}
                      alt={`Card Logo ${index + 1}`}
                      className="w-full h-full object-contain"
                    />
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className="custom-pagination swiper-pagination absolute bottom-4 flex justify-center space-x-2"></div>
    </div>
  );
};

export default ServiceCardSection;
