import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebook, FaYoutube, FaSpotify } from 'react-icons/fa';
import DamiseLogo from '../assets/images/Damise-logo-beyaz-seffaf-kucuk.png';
const Footer: React.FC = () => {
  return (
    <>
      {/* Üst kısma dalga SVG */}
      <div className="relative -mb-1"> {/* Negatif marginn */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="w-full h-auto">
          <path fill="#991b1b" fill-opacity="1" d="M0,256L60,229.3C120,203,240,149,360,149.3C480,149,600,203,720,202.7C840,203,960,149,1080,144C1200,139,1320,181,1380,202.7L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
      </div>


      <footer className="bg-red-800 text-white p-6">
        
          <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            {/* Sol taraf - DAMISE logosu */}
            <div>
              <img
              src={DamiseLogo}
                alt="Damise Logo"
                className="h-8" // Logo boyutunu istediğiniz gibi ayarlayabilirsiniz
              />
            </div>


          {/* Orta kısım - Sosyal medya ikonları */}
          <div className="flex space-x-4 justify-center md:justify-start">
            <a
              href="https://www.linkedin.com/company/damise"
              aria-label="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300">
              <FaLinkedin size={24} />
            </a>
            <a
              href="https://www.instagram.com/damise_global/"
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300">
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.facebook.com/DamiseGlobal"
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300">
              <FaFacebook size={24} />
            </a>
            <a
              href="https://www.youtube.com/@damiseglobal"
              aria-label="YouTube"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300">
              <FaYoutube size={24} />
            </a>
            <a
              href="https://open.spotify.com/show/0TUHY6XUPuHD9tNik3U23Z"
              aria-label="Spotify"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300">
              <FaSpotify size={24} />
            </a>
          </div>

          <p className="text-center md:text-left">+90 216 999 18 50</p>
          {/* Sağ taraf - İletişim bilgileri */}
          <div className="text-center md:text-right">
            <p>Çamlık Mah. Şenol Güneş Bulvarı</p>
            <p>Dinç Sok. No:4 Miyar Plaza K:13</p>
            <p>D:78-79, 34774 Ümraniye/İstanbul</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
