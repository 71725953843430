import React from 'react';

const CountryOptions = () => {
    return (
        <>
            <option value="">Country *</option>
            <option value="624ee17865b03eac8142740b">Afghanistan</option>
            <option value="624ee17865b03eac8142740c">Aland Islands</option>
            <option value="624ee17865b03eac8142740d">Albania</option>
            <option value="624ee17865b03eac8142740e">Algeria</option>
            <option value="624ee17865b03eac8142740f">American Samoa</option>
            <option value="624ee17865b03eac81427410">Andorra</option>
            <option value="624ee17865b03eac81427411">Angola</option>
            <option value="624ee17865b03eac81427412">Anguilla</option>
            <option value="624ee17865b03eac81427413">Antarctica</option>
            <option value="624ee17865b03eac81427414">Antigua And Barbuda</option>
            <option value="624ee17865b03eac81427415">Argentina</option>
            <option value="624ee17865b03eac81427416">Armenia</option>
            <option value="624ee17865b03eac81427417">Aruba</option>
            <option value="624ee17865b03eac81427418">Australia</option>
            <option value="624ee17865b03eac81427419">Austria</option>
            <option value="624ee17865b03eac8142741a">Azerbaijan</option>
            <option value="624ee17865b03eac8142741b">Bahamas The</option>
            <option value="624ee17865b03eac8142741c">Bahrain</option>
            <option value="624ee17865b03eac8142741d">Bangladesh</option>
            <option value="624ee17865b03eac8142741e">Barbados</option>
            <option value="624ee17865b03eac8142741f">Belarus</option>
            <option value="624ee17865b03eac81427420">Belgium</option>
            <option value="624ee17865b03eac81427421">Belize</option>
            <option value="624ee17865b03eac81427422">Benin</option>
            <option value="624ee17865b03eac81427423">Bermuda</option>
            <option value="624ee17865b03eac81427424">Bhutan</option>
            <option value="624ee17865b03eac81427425">Bolivia</option>
            <option value="624ee17865b03eac81427426">Bonaire, Sint Eustatius and Saba</option>
            <option value="624ee17865b03eac81427427">Bosnia and Herzegovina</option>
            <option value="624ee17865b03eac81427428">Botswana</option>
            <option value="624ee17865b03eac81427429">Bouvet Island</option>
            <option value="624ee17865b03eac8142742a">Brazil</option>
            <option value="624ee17865b03eac8142742b">British Indian Ocean Territory</option>
            <option value="624ee17865b03eac8142742c">Brunei</option>
            <option value="624ee17865b03eac8142742d">Bulgaria</option>
            <option value="624ee17865b03eac8142742e">Burkina Faso</option>
            <option value="624ee17865b03eac8142742f">Burundi</option>
            <option value="624ee17865b03eac81427430">Cambodia</option>
            <option value="624ee17865b03eac81427431">Cameroon</option>
            <option value="624ee17865b03eac81427432">Canada</option>
            <option value="624ee17865b03eac81427433">Cape Verde</option>
            <option value="624ee17865b03eac81427434">Cayman Islands</option>
            <option value="624ee17865b03eac81427435">Central African Republic</option>
            <option value="624ee17865b03eac81427436">Chad</option>
            <option value="624ee17865b03eac81427437">Chile</option>
            <option value="624ee17865b03eac81427438">China</option>
            <option value="624ee17865b03eac81427439">Christmas Island</option>
            <option value="624ee17865b03eac8142743a">Cocos (Keeling) Islands</option>
            <option value="624ee17865b03eac8142743b">Colombia</option>
            <option value="624ee17865b03eac8142743c">Comoros</option>
            <option value="624ee17865b03eac8142743d">Congo</option>
            <option value="624ee17865b03eac8142743e">Cook Islands</option>
            <option value="624ee17865b03eac8142743f">Costa Rica</option>
            <option value="624ee17865b03eac81427440">Cote D'Ivoire (Ivory Coast)</option>
            <option value="624ee17865b03eac81427441">Croatia</option>
            <option value="624ee17865b03eac81427442">Cuba</option>
            <option value="624ee17865b03eac81427443">Curaçao</option>
            <option value="624ee17865b03eac81427444">Cyprus</option>
            <option value="624ee17865b03eac81427445">Czech Republic</option>
            <option value="624ee17865b03eac81427446">Democratic Republic of the Congo</option>
            <option value="624ee17865b03eac81427447">Denmark</option>
            <option value="624ee17865b03eac81427448">Djibouti</option>
            <option value="624ee17865b03eac81427449">Dominica</option>
            <option value="624ee17865b03eac8142744a">Dominican Republic</option>
            <option value="624ee17865b03eac8142744b">East Timor</option>
            <option value="624ee17865b03eac8142744c">Ecuador</option>
            <option value="624ee17865b03eac8142744d">Egypt</option>
            <option value="624ee17865b03eac8142744e">El Salvador</option>
            <option value="624ee17865b03eac8142744f">Equatorial Guinea</option>
            <option value="624ee17865b03eac81427450">Eritrea</option>
            <option value="624ee17865b03eac81427451">Estonia</option>
            <option value="624ee17865b03eac81427452">Ethiopia</option>
            <option value="624ee17865b03eac81427453">Falkland Islands</option>
            <option value="624ee17865b03eac81427454">Faroe Islands</option>
            <option value="624ee17865b03eac81427455">Fiji Islands</option>
            <option value="624ee17865b03eac81427456">Finland</option>
            <option value="624ee17865b03eac81427457">France</option>
            <option value="624ee17865b03eac81427458">French Guiana</option>
            <option value="624ee17865b03eac81427459">French Polynesia</option>
            <option value="624ee17865b03eac8142745a">French Southern Territories</option>
            <option value="624ee17865b03eac8142745b">Gabon</option>
            <option value="624ee17865b03eac8142745c">Gambia The</option>
            <option value="624ee17865b03eac8142745d">Georgia</option>
            <option value="624ee17865b03eac8142745e">Germany</option>
            <option value="624ee17865b03eac8142745f">Ghana</option>
            <option value="624ee17865b03eac81427460">Gibraltar</option>
            <option value="624ee17865b03eac81427461">Greece</option>
            <option value="624ee17865b03eac81427462">Greenland</option>
            <option value="624ee17865b03eac81427463">Grenada</option>
            <option value="624ee17865b03eac81427464">Guadeloupe</option>
            <option value="624ee17865b03eac81427465">Guam</option>
            <option value="624ee17865b03eac81427466">Guatemala</option>
            <option value="624ee17865b03eac81427467">Guernsey and Alderney</option>
            <option value="624ee17865b03eac81427468">Guinea</option>
            <option value="624ee17865b03eac81427469">Guinea-Bissau</option>
            <option value="624ee17865b03eac8142746a">Guyana</option>
            <option value="624ee17865b03eac8142746b">Haiti</option>
            <option value="624ee17865b03eac8142746c">Heard Island and McDonald Islands</option>
            <option value="624ee17865b03eac8142746d">Honduras</option>
            <option value="624ee17865b03eac8142746e">Hong Kong S.A.R.</option>
            <option value="624ee17865b03eac8142746f">Hungary</option>
            <option value="624ee17865b03eac81427470">Iceland</option>
            <option value="624ee17865b03eac81427471">India</option>
            <option value="624ee17865b03eac81427472">Indonesia</option>
            <option value="624ee17865b03eac81427473">Iran</option>
            <option value="624ee17865b03eac81427474">Iraq</option>
            <option value="624ee17865b03eac81427475">Ireland</option>
            <option value="624ee17865b03eac81427476">Israel</option>
            <option value="624ee17865b03eac81427477">Italy</option>
            <option value="624ee17865b03eac81427478">Jamaica</option>
            <option value="624ee17865b03eac81427479">Japan</option>
            <option value="624ee17865b03eac8142747a">Jersey</option>
            <option value="624ee17865b03eac8142747b">Jordan</option>
            <option value="624ee17865b03eac8142747c">Kazakhstan</option>
            <option value="624ee17865b03eac8142747d">Kenya</option>
            <option value="624ee17865b03eac8142747e">Kiribati</option>
            <option value="624ee17865b03eac8142747f">Kosovo</option>
            <option value="624ee17865b03eac81427480">Kuwait</option>
            <option value="624ee17865b03eac81427481">Kyrgyzstan</option>
            <option value="624ee17865b03eac81427482">Laos</option>
            <option value="624ee17865b03eac81427483">Latvia</option>
            <option value="624ee17865b03eac81427484">Lebanon</option>
            <option value="624ee17865b03eac81427485">Lesotho</option>
            <option value="624ee17865b03eac81427486">Liberia</option>
            <option value="624ee17865b03eac81427487">Libya</option>
            <option value="624ee17865b03eac81427488">Liechtenstein</option>
            <option value="624ee17865b03eac81427489">Lithuania</option>
            <option value="624ee17865b03eac8142748a">Luxembourg</option>
            <option value="624ee17865b03eac8142748b">Macau S.A.R.</option>
            <option value="624ee17865b03eac8142748c">Macedonia</option>
            <option value="624ee17865b03eac8142748d">Madagascar</option>
            <option value="624ee17865b03eac8142748e">Malawi</option>
            <option value="624ee17865b03eac8142748f">Malaysia</option>
            <option value="624ee17865b03eac81427490">Maldives</option>
            <option value="624ee17865b03eac81427491">Mali</option>
            <option value="624ee17865b03eac81427492">Malta</option>
            <option value="624ee17865b03eac81427493">Man (Isle of)</option>
            <option value="624ee17865b03eac81427494">Marshall Islands</option>
            <option value="624ee17865b03eac81427495">Martinique</option>
            <option value="624ee17865b03eac81427496">Mauritania</option>
            <option value="624ee17865b03eac81427497">Mauritius</option>
            <option value="624ee17865b03eac81427498">Mayotte</option>
            <option value="624ee17865b03eac81427499">Mexico</option>
            <option value="624ee17865b03eac8142749a">Micronesia</option>
            <option value="624ee17865b03eac8142749b">Moldova</option>
            <option value="624ee17865b03eac8142749c">Monaco</option>
            <option value="624ee17865b03eac8142749d">Mongolia</option>
            <option value="624ee17865b03eac8142749e">Montenegro</option>
            <option value="624ee17865b03eac8142749f">Montserrat</option>
            <option value="624ee17865b03eac814274a0">Morocco</option>
            <option value="624ee17865b03eac814274a1">Mozambique</option>
            <option value="624ee17865b03eac814274a2">Myanmar</option>
            <option value="624ee17865b03eac814274a3">Namibia</option>
            <option value="624ee17865b03eac814274a4">Nauru</option>
            <option value="624ee17865b03eac814274a5">Nepal</option>
            <option value="624ee17865b03eac814274a6">Netherlands</option>
            <option value="624ee17865b03eac814274a7">New Caledonia</option>
            <option value="624ee17865b03eac814274a8">New Zealand</option>
            <option value="624ee17865b03eac814274a9">Nicaragua</option>
            <option value="624ee17865b03eac814274aa">Niger</option>
            <option value="624ee17865b03eac814274ab">Nigeria</option>
            <option value="624ee17865b03eac814274ac">Niue</option>
            <option value="624ee17865b03eac814274ad">Norfolk Island</option>
            <option value="624ee17865b03eac814274ae">North Korea</option>
            <option value="624ee17865b03eac814274af">Northern Mariana Islands</option>
            <option value="624ee17865b03eac814274b0">Norway</option>
            <option value="624ee17865b03eac814274b1">Oman</option>
            <option value="624ee17865b03eac814274b2">Pakistan</option>
            <option value="624ee17865b03eac814274b3">Palau</option>
            <option value="624ee17865b03eac814274b4">Palestinian Territory Occupied</option>
            <option value="624ee17865b03eac814274b5">Panama</option>
            <option value="624ee17865b03eac814274b6">Papua new Guinea</option>
            <option value="624ee17865b03eac814274b7">Paraguay</option>
            <option value="624ee17865b03eac814274b8">Peru</option>
            <option value="624ee17865b03eac814274b9">Philippines</option>
            <option value="624ee17865b03eac814274ba">Pitcairn Island</option>
            <option value="624ee17865b03eac814274bb">Poland</option>
            <option value="624ee17865b03eac814274bc">Portugal</option>
            <option value="624ee17865b03eac814274bd">Puerto Rico</option>
            <option value="624ee17865b03eac814274be">Qatar</option>
            <option value="624ee17865b03eac814274bf">Reunion</option>
            <option value="624ee17865b03eac814274c0">Romania</option>
            <option value="624ee17865b03eac814274c1">Russia</option>
            <option value="624ee17865b03eac814274c2">Rwanda</option>
            <option value="624ee17865b03eac814274c3">Saint Helena</option>
            <option value="624ee17865b03eac814274c4">Saint Kitts And Nevis</option>
            <option value="624ee17865b03eac814274c5">Saint Lucia</option>
            <option value="624ee17865b03eac814274c6">Saint Pierre and Miquelon</option>
            <option value="624ee17865b03eac814274c7">Saint Vincent And The Grenadines</option>
            <option value="624ee17865b03eac814274c8">Saint-Barthelemy</option>
            <option value="624ee17865b03eac814274c9">Saint-Martin (French part)</option>
            <option value="624ee17865b03eac814274ca">Samoa</option>
            <option value="624ee17865b03eac814274cb">San Marino</option>
            <option value="624ee17865b03eac814274cc">Sao Tome and Principe</option>
            <option value="624ee17865b03eac814274cd">Saudi Arabia</option>
            <option value="624ee17865b03eac814274ce">Senegal</option>
            <option value="624ee17865b03eac814274cf">Serbia</option>
            <option value="624ee17865b03eac814274d0">Seychelles</option>
            <option value="624ee17865b03eac814274d1">Sierra Leone</option>
            <option value="624ee17865b03eac814274d2">Singapore</option>
            <option value="624ee17865b03eac814274d3">Sint Maarten (Dutch part)</option>
            <option value="624ee17865b03eac814274d4">Slovakia</option>
            <option value="624ee17865b03eac814274d5">Slovenia</option>
            <option value="624ee17865b03eac814274d6">Solomon Islands</option>
            <option value="624ee17865b03eac814274d7">Somalia</option>
            <option value="624ee17865b03eac814274d8">South Africa</option>
            <option value="624ee17865b03eac814274d9">South Georgia</option>
            <option value="624ee17865b03eac814274da">South Korea</option>
            <option value="624ee17865b03eac814274db">South Sudan</option>
            <option value="624ee17865b03eac814274dc">Spain</option>
            <option value="624ee17865b03eac814274dd">Sri Lanka</option>
            <option value="624ee17865b03eac814274de">Sudan</option>
            <option value="624ee17865b03eac814274df">Suriname</option>
            <option value="624ee17865b03eac814274e0">Svalbard And Jan Mayen Islands</option>
            <option value="624ee17865b03eac814274e1">Swaziland</option>
            <option value="624ee17865b03eac814274e2">Sweden</option>
            <option value="624ee17865b03eac814274e3">Switzerland</option>
            <option value="624ee17865b03eac814274e4">Syria</option>
            <option value="624ee17865b03eac814274e5">Taiwan</option>
            <option value="624ee17865b03eac814274e6">Tajikistan</option>
            <option value="624ee17865b03eac814274e7">Tanzania</option>
            <option value="624ee17865b03eac814274e8">Thailand</option>
            <option value="624ee17865b03eac814274e9">Togo</option>
            <option value="624ee17865b03eac814274ea">Tokelau</option>
            <option value="624ee17865b03eac814274eb">Tonga</option>
            <option value="624ee17865b03eac814274ec">Trinidad And Tobago</option>
            <option value="624ee17865b03eac814274ed">Tunisia</option>
            <option value="624ee17865b03eac814274ee">Türkiye</option>
            <option value="624ee17865b03eac814274ef">Turkmenistan</option>
            <option value="624ee17865b03eac814274f0">Turks And Caicos Islands</option>
            <option value="624ee17865b03eac814274f1">Tuvalu</option>
            <option value="624ee17865b03eac814274f2">Uganda</option>
            <option value="624ee17865b03eac814274f3">Ukraine</option>
            <option value="624ee17865b03eac814274f4">United Arab Emirates</option>
            <option value="624ee17865b03eac814274f5">United Kingdom</option>
            <option value="624ee17865b03eac814274f6">United States</option>
            <option value="624ee17865b03eac814274f7">United States Minor Outlying Islands</option>
            <option value="624ee17865b03eac814274f8">Uruguay</option>
            <option value="624ee17865b03eac814274f9">Uzbekistan</option>
            <option value="624ee17865b03eac814274fa">Vanuatu</option>
            <option value="624ee17865b03eac814274fb">Vatican City State (Holy See)</option>
            <option value="624ee17865b03eac814274fc">Venezuela</option>
            <option value="624ee17865b03eac814274fd">Vietnam</option>
            <option value="624ee17865b03eac814274fe">Virgin Islands (British)</option>
            <option value="624ee17865b03eac814274ff">Virgin Islands (US)</option>
            <option value="624ee17865b03eac81427500">Wallis And Futuna Islands</option>
            <option value="624ee17865b03eac81427501">Western Sahara</option>
            <option value="624ee17865b03eac81427502">Yemen</option>
            <option value="624ee17865b03eac81427503">Zambia</option>
            <option value="624ee17865b03eac81427504">Zimbabwe</option>
        </>
    );
};

export default CountryOptions;