import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { FcApproval } from 'react-icons/fc';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';  // Dinamik URL kontrolü için

interface ShareLinksProps {
    title: string;
    description: string;
    socialImage: string;
}

const ShareLinks: React.FC<ShareLinksProps> = ({ title, description, socialImage }) => {
    const [isCopied, setIsCopied] = useState(false);
    const location = useLocation();
    const fullUrl = `${window.location.origin}${location.pathname}`;  // Dinamik URL

    useEffect(() => {

    }, [title, description, fullUrl, socialImage]);

    const handleCopyUrl = () => {
        navigator.clipboard.writeText(fullUrl)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 3000);
            })
            .catch(err => {
                console.error('URL kopyalanamadı: ', err);
            });
    };

    return (
        <>
            {/* Dinamik SEO meta etiketleri */}
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={socialImage} />
                <meta property="og:url" content={fullUrl} />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={socialImage} />
            </Helmet>

            {/* Link Kopyalandı Mesajı */}
            {isCopied && (
                <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-white border border-gray-200 shadow-md rounded-md px-4 py-2 z-50 flex items-center space-x-2">
                    <FcApproval className="w-5 h-5" />
                    <span>Link başarıyla kopyalandı</span>
                </div>
            )}

            <div className="fixed top-1/4 left-4 flex flex-col items-center space-y-2 z-50 opacity-30 transition-opacity duration-300 hover:opacity-100">
                <button className="group p-1 bg-gray-100 rounded-full shadow hover:bg-white transition">
                    <MdContentCopy className="text-gray-600 group-hover:text-black" size={20} onClick={handleCopyUrl} />
                </button>
                <a href={`https://api.whatsapp.com/send?text=${encodeURIComponent(`${socialImage}  --  ${title} --- ${description}: ${fullUrl}`)}`} target="_blank" rel="noopener noreferrer" className="group p-1 bg-gray-100 rounded-full shadow hover:bg-white transition">
                    <FaWhatsapp className="text-green-500 group-hover:text-black" size={20} />
                </a>
                <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(fullUrl)}&text=${encodeURIComponent(title)}&via=yourTwitterHandle`} target="_blank" rel="noopener noreferrer" className="group p-1 bg-gray-100 rounded-full shadow hover:bg-white transition">
                    <FaTwitter className="text-blue-500 group-hover:text-black" size={20} />
                </a>
                <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(fullUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`} target="_blank" rel="noopener noreferrer" className="group p-1 bg-gray-100 rounded-full shadow hover:bg-white transition">
                    <FaLinkedinIn className="text-blue-700 group-hover:text-black" size={20} />
                </a>
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(fullUrl)}&quote=${encodeURIComponent(description)}`} target="_blank" rel="noopener noreferrer" className="group p-1 bg-gray-100 rounded-full shadow hover:bg-white transition">
                    <FaFacebookF className="text-blue-700 group-hover:text-black" size={20} />
                </a>
            </div>
        </>
    );
};

export default ShareLinks;
