import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import axios from 'axios'

interface HeaderSectionProps {
    componentData: {
        title: string;
        logoMedia: string;
        componentName: string;
    };
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ componentData }) => {
    // Intersection Observer hook'u kullanarak görünürlük kontrolü yapıyoruz
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    useEffect(() => {
        if (inView) {
            // Backend'e post isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  // URL'den alınan launchName kullanılıyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentName: componentData.componentName,
                componentCount: 1, // Component sayısını burada belirtebilirsiniz
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl]);

    return (
        <div
            ref={ref}
            className="flex justify-between items-center w-full h-[120px] mx-auto bg-white relative p-4 md:p-6" // Padding mobil ve büyük ekranlar için ayarlandı
        >
            {/* Sol tarafta başlık */}
            <motion.h1
                className="text-gray-800 font-bold text-[40px] md:text-[70px] pl-8" // Font boyutu mobil ve büyük ekranlar için optimize edildi
                style={{
                    fontFamily: 'Radhanji, sans-serif', // Font ailesi Radhanji olarak ayarlandı
                    paddingLeft: '20px', // Sol padding mobilde azaltıldı
                    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.3)', // Gölgelendirme eklendi
                }}
                initial={{ x: -100, opacity: 0 }}
                animate={inView ? { x: 0, opacity: 1 } : {}}
                transition={{ duration: 1.2, ease: 'easeOut' }} // Animasyon süresi optimize edildi
            >
                {componentData.title}
            </motion.h1>

            {/* Sağ tarafta logo */}
            <motion.div
                 className="flex items-center h-auto pr-8" 
                 initial={{ x: 100, opacity: 0 }}
                 animate={inView ? { x: 0, opacity: 1 } : {}}
                 transition={{ duration: 1.2, ease: 'easeOut' }}
            >
                <img
                    src={componentData.logoMedia}
                    alt="Logo"
                    className="object-contain w-[70px] h-[70px] md:w-[120px] md:h-[120px]" // Mobil ve büyük ekranlar için logo boyutu optimize edildi
                />
            </motion.div>
        </div>
    );
};

export default HeaderSection;
