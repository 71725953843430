import React from 'react';
import { motion } from 'framer-motion';

interface SpacingSectionProps {
    componentData: {
        spacingValue: number;
    };
}

const SpacingSection: React.FC<SpacingSectionProps> = ({ componentData }) => {
    const { spacingValue } = componentData;

    // Ekran genişliğine göre spacingValue'yu ayarlamak
    const getResponsiveSpacing = () => {
        if (window.innerWidth < 640) { // Mobil ekranlar için
            return spacingValue / 2; // spacingValue'yu küçültme
        } else if (window.innerWidth < 1024) { // Tablet ekranlar için
            return spacingValue * 0.75; // spacingValue'yu biraz küçültme
        }
        return spacingValue; // Büyük ekranlar için orijinal spacingValue
    };

    const responsiveSpacing = getResponsiveSpacing();

    return (
        <div
            className="w-full"
            style={{
                marginTop: `${responsiveSpacing}px`,
                marginBottom: `${responsiveSpacing}px`
            }} // Dinamik boşluk oluşturma
        >
            <motion.div
                className="w-full h-[50px] bg-transparent"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
            />
        </div>
    );
};

export default SpacingSection;
