import React, { useEffect, useRef } from 'react';

interface PopupProps {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;  // Kabul edildiğinde çalışacak fonksiyon
    language: 'en' | 'tr'; // Dil seçeneği
}

const Popup: React.FC<PopupProps> = ({ isOpen, onClose, onAccept, language }) => {
    const popupRef = useRef<HTMLDivElement>(null);

    // Pencerenin dışına tıklanınca popup'ı kapatmak için
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const handleAccept = () => {
        onAccept();  // Checkbox'ı işaretle
        onClose();   // Popup'u kapat
    };

    // Dil seçeneklerine göre metinleri tanımlayın
    const texts = {
        en: {
            title: "Personal Data Processing Notice",
            introduction: "As Damise Bilişim Hizmetleri A.Ş., we would like to inform you about personal data processing, transfer of processed personal data, collection methods of your personal data, legal reasons, and your rights listed in Article 11 of the KVKK.",
            processingPurposes: "We may process your personal data for the purposes explained below, in compliance with the law and the principles of honesty.",
            dataController: "Data Controller and Representative",
            controllerInfo: "The Data Controller is “Damise Bilişim Hizmetleri A.Ş.” located at Çamlık Mah. Şenol Güneş Bulvarı Dinç Sok. No:4 Muyar Plaza K:13 D:78-79 Ümraniye/Istanbul.",
            processedData: "Your Processed Personal Data",
            dataList: [
                "Identity Information (Your name, surname, ID number, etc.)",
                "Contact Information (Your address, mobile number, email, etc.)",
                "Financial Data (Your bank account number, credit card information, payment information, billing information)",
                "Customer Transaction (Order and request information, information on receipts)",
                "Marketing (Shopping history, surveys, cookie records, information obtained from campaign studies)",
                "Transaction Security (Website browsing information, IP address, website login/logout information, passwords)",
                "Other personal data you provide in the context of requests, suggestions, and complaints."
            ],
            accept: "I Accept",
        },
        tr: {
            title: "KİŞİSEL VERİLERİN İŞLENMESİ AYDINLATMA METNİ",
            introduction: "Damise Bilişim Hizmetleri A.Ş. olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 10. maddesi uyarınca sizi kişisel veri işleme, işlenen kişisel verilerinizin aktarılması, kişisel verilerinizin toplanma yöntemleri ve hukuki sebebi ile KVKK md. 11’de sayılan diğer haklarınızla ilgili olarak bilgilendirmek ve aydınlatmak isteriz.",
            processingPurposes: "Kişisel verilerinizi aşağıda açıklanan amaçlar kapsamında; hukuka ve dürüstlük kurallarına uygun bir şekilde işleyebilecek, kaydedebilecek, saklayabilecek, sınıflandırabilecek, güncelleyebilecek ve mevzuatın izin verdiği hallerde ve/veya işlendikleri amaçla sınırlı olarak 3. kişilere açıklayabilecek/aktarabileceğiz.",
            dataController: "VERİ SORUMLUSU ve TEMSİLCİSİ",
            controllerInfo: "Kanun uyarınca “Çamlık Mah. Şenol Güneş Bulvarı Dinç Sok. No:4 Muyar Plaza K:13 D:78-79 Ümraniye/İstanbul” adresinde faaliyet gösteren “Damise Bilişim Hizmetleri A.Ş.” (Bundan böyle kısaca “DAMISE” olarak anılacaktır) Veri Sorumlusudur.",
            processedData: "İŞLENEN KİŞİSEL VERİLERİNİZ",
            dataList: [
                "Kimlik Bilgileriniz (Adınız, soyadınız, T.C. kimlik numaranız gibi sizi tanımlayabileceğimiz kimlik verileriniz)",
                "İletişim Bilgileriniz (Adresiniz, cep telefonunuz, elektronik posta adresiniz ve sair iletişim verileriniz)",
                "Finansal Verileriniz (Banka hesap numaranız, IBAN numaranız, kredi kartı bilginiz, ödeme bilgileriniz, faturalama bilgileriniz)",
                "Müşteri İşlem (Sipariş ve talep bilgisi, gişe dekontlarındaki bilgiler gibi)",
                "Pazarlama (Alışveriş geçmişi bilgisi, anket, çerez kayıtları, kampanya çalışmasıyla elde edilen bilgiler gibi)",
                "İşlem Güvenliği (Web sitesi gezinme bilgileri, IP adresi, internet sitesi giriş/çıkış bilgileri, şifre ve parola bilgileri gibi)",
                "Talep, öneri ve şikâyetleriniz çerçevesinde bizzat ilettiğiniz diğer kişisel verileriniz"
            ],
            accept: "Kabul Ediyorum",
        },
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div
                ref={popupRef}
                className="bg-white p-6 rounded-lg max-w-lg w-full relative max-h-[80vh] overflow-y-auto 
                          md:max-w-lg md:p-6 sm:max-w-sm sm:p-4 sm:text-sm"
            >
                <h2 className="text-xl font-semibold mb-4 sm:text-lg">{texts[language].title}</h2>
                <p className="mb-4">{texts[language].introduction}</p>
                <p className="mb-4">{texts[language].processingPurposes}</p>

                <h3 className="text-lg font-semibold mb-2 sm:text-base">{texts[language].dataController}</h3>
                <p className="mb-4">{texts[language].controllerInfo}</p>

                <h3 className="text-lg font-semibold mb-2 sm:text-base">{texts[language].processedData}</h3>
                <p className="mb-4">
                    {texts[language].dataList.map((item, index) => (
                        <li key={index} className="list-disc pl-5">{item}</li>
                    ))}
                </p>

                {/* Butonu sağ alta hizalıyoruz */}
                <div className="flex justify-end mt-6">
                    <button
                        className="bg-white border border-blue-500 text-blue-500 px-6 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition duration-300
                                   sm:px-4 sm:py-2 sm:text-sm"
                        onClick={handleAccept}  // Kabul edildiğinde handleAccept fonksiyonu çalışır
                    >
                        {texts[language].accept}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
