import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import axios from 'axios';
interface RightTextCardSectionProps {
    componentData: {
        text: string;
        media: string;
        mediaPoster?: string; // Kapak fotoğrafı için alan
        componentName:string
    };
}

const RightTextCardSection: React.FC<RightTextCardSectionProps> = ({ componentData }) => {
    const { text, media, mediaPoster } = componentData;
    const [isPlaying, setIsPlaying] = useState(false);
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); 
    const launchUrl = urlParam || 'homepage'; 
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0.3,
    });
    useEffect(() => {
        if (inView) {
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  
                visitorId: sessionStorage.getItem('visitorId'),  
                componentName: componentData.componentName,
                componentCount: 1, 
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl, componentData.componentName]);

    const renderMedia = (media: string, mediaPoster?: string) => {
        const fileExtension = media.split('.').pop()?.toLowerCase();
        const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
        const videoTypes = ['mp4', 'webm', 'ogg'];
        const audioTypes = ['mp3', 'wav', 'ogg'];

        if (imageTypes.includes(fileExtension || '')) {
            return (
                <img
                    src={media}
                    alt="Selected Media"
                    className="w-full h-full object-cover"
                />
            );
        } else if (videoTypes.includes(fileExtension || '')) {
            return (
                <video
                    className="w-full h-full object-cover"
                    controls
                    poster={mediaPoster} // Kapak fotoğrafı burada ayarlandı
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    style={{ outline: 'none' }}
                >
                    <source src={media} type={`video/${fileExtension}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </video>
            );
        } else if (audioTypes.includes(fileExtension || '')) {
            return (
                <audio controls className="w-full h-full">
                    <source src={media} type={`audio/${fileExtension}`} />
                    Tarayıcınız bu sesi desteklemiyor.
                </audio>
            );
        } else {
            return <p>Desteklenmeyen medya formatı: {fileExtension}</p>;
        }
    };

    const textVariants = {
        hidden: { opacity: 0, x: 50 },
        visible: { opacity: 1, x: 0, transition: { duration: 1, ease: 'easeInOut' } },
    };

    return (
        <div
            ref={ref}
            style={{
                height: '70vh',
                width: '85vw',
            }}
            className="w-full max-w-[95vw] mx-auto flex lg:flex-row-reverse flex-col bg-gray-70 rounded-3xl shadow-xl overflow-hidden"
        >
            <div className="lg:w-[50%] w-full h-full p-6 bg-gray-100 flex items-center justify-center">
                <motion.p
                    initial="hidden"
                    animate={inView ? 'visible' : 'hidden'}
                    variants={textVariants}
                    className="text-black text-right"
                    style={{
                        maxWidth: '600px',
                        fontSize: 'clamp(18px, 4vw, 35px)',
                        lineHeight: '1.2',
                        fontFamily: 'Radhanji, sans-serif',
                        textAlign: 'right',
                        wordWrap: 'break-word',
                    }}
                >
                    {text}
                </motion.p>
            </div>

            <div className="lg:w-[50%] w-full h-[50vh] lg:h-full">
                {renderMedia(media, mediaPoster)}
            </div>
        </div>
    );
};

export default RightTextCardSection;
