import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import LaunchLayout from './pages/LaunchLayout';
import HomepagePreviewPage from './pages/HomepagePreviewPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFoundPage from './pages/NotFoundPage';
import LaunchSearchPage from './UI/sections/LaunchSearchPage';
import LoginPage from './pages/LoginPage';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <ToastContainer position="bottom-right" autoClose={5000} />
        <Routes>
          {/* Genel kullanıcı rotaları */}
          <Route path="/" element={<HomepagePreviewPage />} />
          <Route path="/:launchUrl" element={<LaunchLayout />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/access-denied" element={<NotFoundPage />} />
          {/* Doğru rota ve componentData parametresiyle yönlendirme yapıyoruz */}
          <Route path="/yayindaki-lansmanlar" element={<LaunchSearchPage componentData="ongoing" />} />
          <Route path="/gelecek-lansmanlar" element={<LaunchSearchPage componentData="upcoming" />} />
          <Route path="/gecmis-lansmanlar" element={<LaunchSearchPage componentData="past" />} />
          {/* 404 Sayfası */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
