import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface TitleCardSectionProps {
    componentData: {
        title: string;
        componentName: string
    };
}

const TitleCardSection: React.FC<TitleCardSectionProps> = ({ componentData }) => {
    const { title } = componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor

    // Intersection Observer ile bileşen görünür olduğunda animasyonu tetiklemek için ref ekliyoruz
    const { ref, inView } = useInView({
        triggerOnce: true,   // Sadece bir kez tetiklensin
        threshold: 0.3       // Kartın %30'u görünür olduğunda tetikleme yapılır
    });

    useEffect(() => {
        if (inView) {
            // Backend'e post isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  // URL'den alınan launchName kullanılıyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentName: componentData.componentName,
                componentCount: 1, // Component sayısını burada belirtebilirsiniz
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl]);

    return (
        <div className="w-full flex justify-start items-center pt-6 pb-0 ml-5 md:ml-20"> {/* Sol boşluk mobilde azaltıldı */}
            {/* Başlık ve Çizgi */}
            <motion.div
                ref={ref}  // Intersection observer için ref ekliyoruz
                className="flex items-center w-full"
                initial={{ scale: 0.8, opacity: 0 }}  // Başlangıç durumu (küçük boyut ve saydam)
                animate={inView ? { scale: 1, opacity: 1 } : {}} // Sayfa kaydırıldığında büyütme ve görünürlük sağlanır
                transition={{ duration: 0.6, ease: "easeInOut" }} // Geçiş süresi ve yumuşaklık
            >
                {/* Başlık */}
                <motion.h2
                    className="text-[28px] md:text-[38px] font-bold text-gray-700 w-auto" // Mobilde yazı boyutu küçültüldü
                    style={{ fontFamily: 'Radhanji, sans-serif' }} // Font ailesini Radhanji olarak ayarladık
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : {}} // Sayfa kaydırıldığında başlık görünür hale gelir
                    transition={{ duration: 0.6, delay: 0.3 }} // Biraz gecikme ekleyerek başlık animasyonu sağlanır
                >
                    {title}
                </motion.h2>

                {/* Yanındaki Gradient Çizgi */}
                <div className="flex-grow ml-4 h-[1px] bg-gradient-to-r from-gray-500 to-transparent sm:h-[2px]"></div> {/* Çizginin yükseklik sorunu çözüldü */}
            </motion.div>
        </div>
    );
};

export default TitleCardSection;
