import React, { useState, useEffect } from 'react';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';
import api from '../../services/apiService';
import { BsRocketTakeoffFill } from "react-icons/bs";
import { PiInfinityBold } from "react-icons/pi";
import { LuHistory } from "react-icons/lu";
import Cookies from 'js-cookie';
import VisitForm from '../../components/componentmodals/VisitForm';
import { useNavigate } from 'react-router-dom';

export interface LaunchData {
  _id: string;
  launchName: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
}

interface SearchComponentSectionProps {
  componentData: any;
}

const SearchComponentSection: React.FC<SearchComponentSectionProps> = ({ componentData }) => {
  const [launches, setLaunches] = useState<LaunchData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ongoingLaunches, setOngoingLaunches] = useState<LaunchData[]>([]);
  const [upcomingLaunches, setUpcomingLaunches] = useState<LaunchData[]>([]);
  const [pastLaunches, setPastLaunches] = useState<LaunchData[]>([]);
  const [seoDataMap, setSeoDataMap] = useState<{ [key: string]: any }>({});
  const [isOngoingLaunchesOpen, setIsOngoingLaunchesOpen] = useState(false);
  const [isUpcomingLaunchesOpen, setIsUpcomingLaunchesOpen] = useState(false);
  const [isPastLaunchesOpen, setIsPastLaunchesOpen] = useState(false);
  const [showVisitForm, setShowVisitForm] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [showCards, setShowCards] = useState(false); // State to trigger animation

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLaunches = async () => {
      try {
        const response = await api.get<LaunchData[]>(`${process.env.REACT_APP_API_URL}/launch`);
        setLaunches(response.data);
        categorizeLaunches(response.data);
        await fetchAllSeoData(response.data);
      } catch (error) {
        console.error('Lansman verileri alınamadı:', error);
      }
    };
    fetchLaunches();
  }, []);

  useEffect(() => {
    // Reset the showCards to false when closing, then trigger animation when opening..
    if (isOngoingLaunchesOpen || isUpcomingLaunchesOpen || isPastLaunchesOpen) {
      setShowCards(false);
      setTimeout(() => setShowCards(true), 100); // Re-trigger the animation with a small delay
    } else {
      setShowCards(false);
    }
  }, [isOngoingLaunchesOpen, isUpcomingLaunchesOpen, isPastLaunchesOpen]);

  const fetchAllSeoData = async (launches: LaunchData[]) => {
    const seoMap: { [key: string]: any } = {};
    for (const launch of launches) {
      try {
        const response = await api.get(`${process.env.REACT_APP_API_URL}/seosettings/${launch._id}`);
        seoMap[launch._id] = response.data;
      } catch (error) {
        console.error(`SEO verileri alınamadı: ${launch._id}`, error);
      }
    }
    setSeoDataMap(seoMap);
  };

  const categorizeLaunches = (launches: LaunchData[]) => {
    const today = new Date();
    const ongoingLaunches = launches.filter(
      launch => new Date(launch.startDate) <= today && new Date(launch.endDate) >= today
    );
    const upcomingLaunches = launches.filter(
      launch => new Date(launch.startDate) > today
    );
    const pastLaunches = launches.filter(
      launch => new Date(launch.endDate) < today
    );

    setOngoingLaunches(ongoingLaunches);
    setUpcomingLaunches(upcomingLaunches);
    setPastLaunches(pastLaunches);
  };

  const filterAllLaunches = (searchTerm: string) => {
    if (!searchTerm) return launches;
    return launches.filter(launch => {
      const seoTitle = seoDataMap[launch._id]?.title || '';
      const seoKeywords = seoDataMap[launch._id]?.keywords || '';
      return (
        launch.launchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        seoTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        seoKeywords.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setIsOngoingLaunchesOpen(false);
    setIsUpcomingLaunchesOpen(false);
    setIsPastLaunchesOpen(false);
  };

  const toggleOngoingLaunches = () => {
    setIsOngoingLaunchesOpen(!isOngoingLaunchesOpen);
    setIsUpcomingLaunchesOpen(false);
    setIsPastLaunchesOpen(false);
  };

  const toggleUpcomingLaunches = () => {
    setIsUpcomingLaunchesOpen(!isUpcomingLaunchesOpen);
    setIsOngoingLaunchesOpen(false);
    setIsPastLaunchesOpen(false);
  };

  const togglePastLaunches = () => {
    setIsPastLaunchesOpen(!isPastLaunchesOpen);
    setIsOngoingLaunchesOpen(false);
    setIsUpcomingLaunchesOpen(false);
  };

  const handleProtectedLinkClick = (url: string) => {
    const token = Cookies.get('visitFormToken');
    if (token) {
      navigate(url);
    } else {
      setRedirectUrl(url);
      setShowVisitForm(true);
    }
  };

  const renderLaunchCards = (launches: LaunchData[], isUpcoming: boolean = false) => {
    return (
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-8 transition-opacity duration-1000 ${showCards ? 'opacity-100' : 'opacity-0'}`}
      >
        {launches.map((launch, index) => {
          const launchSeoData = seoDataMap[launch._id];
          return (
            <div
              key={launch._id}
              className={`relative p-6 bg-white rounded-lg shadow-md min-h-[350px] transform transition-transform duration-500 hover:scale-105 overflow-hidden`}
              style={{ transitionDelay: `${index * 150}ms` }} // Delay for each card
            >
              {isUpcoming && (
                <div
                  className="absolute top-0 left-0 z-20"
                  style={{
                    width: '220px',
                    height: '60px',
                    top: '10px',
                    left: '-75px',
                    transform: 'rotate(-45deg)',
                    zIndex: 60,
                  }}
                >
                  <div className="bg-red-600 text-white font-bold py-1 px-2 shadow-md flex justify-center items-center h-full">
                    <div className="text-center">
                      <div className="text-xs">{new Date(launch.startDate).toLocaleDateString()}</div>
                      <div className="text-xs">
                        YAYINDA
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="h-60 bg-gray-100 rounded-md flex items-center justify-center overflow-hidden shadow-sm">
                <img
                  src={launchSeoData?.socialImage || 'https://via.placeholder.com/150'}
                  alt="Launch"
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                />
              </div>

              <h3 className="text-lg font-semibold mt-4 text-gray-900">
                {launchSeoData?.title || launch.launchName}
              </h3>

              <p className="text-gray-600 mt-2 line-clamp-2 break-words">
                {launchSeoData?.description || 'Lansman açıklaması burada yer alacak.'}
              </p>
              <button
                onClick={() => handleProtectedLinkClick(`/lansman/${launchSeoData?.url}`)}
                className="text-blue-500 mt-4 underline hover:text-blue-700 float-right"
              >
                Daha fazlası...
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-4 mx-auto min-h-[70vh] max-w-[85vw]">
      <div className="relative w-full mb-8">
        <FaSearch className="absolute left-3 top-3 text-gray-400" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-3 border shadow-lg rounded-sm text-gray-700 focus:outline-none focus:ring-2"
          placeholder="Lansman Ara"
        />
        {searchTerm && (
          <button
            onClick={handleClearSearch}
            className="absolute right-3 top-3 text-sm text-gray-500 bg-gray-200 rounded px-2 py-1 hover:bg-gray-300"
          >
            Temizle
          </button>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div
          className={`border rounded-lg p-6 ${isOngoingLaunchesOpen ? 'bg-gray-200' : 'bg-gray-50'} hover:shadow-lg transition-shadw w-full h-40 flex flex-col justify-center items-center cursor-pointer`}
          onClick={toggleOngoingLaunches}
        >
          <PiInfinityBold className="text-3xl text-black mb-2" />
          <span className="text-lg text-center">Yayındaki Lansmanlar</span>
          <div>
            {isOngoingLaunchesOpen ? <FaChevronUp className="text-black" /> : <FaChevronDown className="text-black" />}
          </div>
        </div>

        <div
          className={`border rounded-lg p-6 ${isUpcomingLaunchesOpen ? 'bg-gray-200' : 'bg-gray-50'} hover:shadow-lg transition-shadow w-full h-40 flex flex-col justify-center items-center cursor-pointer`}
          onClick={toggleUpcomingLaunches}
        >
          <BsRocketTakeoffFill className="text-3xl text-black mb-2" />
          <span className="text-lg text-center">Gelecek Lansmanlar</span>
          <div>
            {isUpcomingLaunchesOpen ? <FaChevronUp className="text-black" /> : <FaChevronDown className="text-black" />}
          </div>
        </div>

        <div
          className={`border rounded-lg p-6 ${isPastLaunchesOpen ? 'bg-gray-200' : 'bg-gray-50'} hover:shadow-lg transition-shadow w-full h-40 flex flex-col justify-center items-center cursor-pointer`}
          onClick={togglePastLaunches}
        >
          <LuHistory className="text-3xl text-black mb-2" />
          <span className="text-lg text-center">Geçmiş Lansmanlar</span>
          <div>
            {isPastLaunchesOpen ? <FaChevronUp className="text-black" /> : <FaChevronDown className="text-black" />}
          </div>
        </div>
      </div>

      <div className="mt-4">
        {searchTerm
          ? renderLaunchCards(filterAllLaunches(searchTerm))
          : (
            <>
              {isOngoingLaunchesOpen && renderLaunchCards(ongoingLaunches)}
              {isUpcomingLaunchesOpen && renderLaunchCards(upcomingLaunches, true)}
              {isPastLaunchesOpen && renderLaunchCards(pastLaunches)}
            </>
          )}
      </div>

      {showVisitForm && (
        <VisitForm
          onClose={() => setShowVisitForm(false)}
          onVerifySuccess={() => {
            if (redirectUrl) {
              navigate(redirectUrl);
            }
          }}
        />
      )}
    </div>
  );
};

export default SearchComponentSection;
