import React, { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface ReelsCardSliderWithExploreSectionProps {
    componentData: {
        reelsCards: Array<{
            media: string;
            title: string;
            subtitle: string;
            exploreUrl: string;
            buttonText: string;
        }>;
        componentName: string; // Backend'e göndermek için componentName ekleniyor
    };
}

const ReelsCardSliderWithExploreSection: React.FC<ReelsCardSliderWithExploreSectionProps> = ({ componentData }) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const [selectedCard, setSelectedCard] = useState<{
        media: string;
        title: string;
        subtitle: string;
        exploreUrl: string;
        buttonText: string;
    } | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const cardWidth = 320; // Kart genişliği

    // Kartları sonsuz döngü için çoğaltıyoruz
    const duplicatedReelsCards = [...componentData.reelsCards, ...componentData.reelsCards];

    // Intersection Observer ile görünürlük kontrolü
    const { ref: inViewRef, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    // Ref birleştirici fonksiyon
    const combineRefs = (...refs: any[]) => (node: any) => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(node);
            } else if (ref) {
                ref.current = node;
            }
        });
    };

    // Backend'e görünür olduğunda POST isteği gönderiyoruz
    useEffect(() => {
        if (inView) {
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,
                componentName: componentData.componentName, // Bileşen adı backend'e gönderiliyor
                visitorId: sessionStorage.getItem('visitorId'), // sessionStorage'dan visitorId alınıyor
                componentCount: componentData.reelsCards.length, // Kart sayısı backend'e gönderiliyor
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, componentData.componentName, componentData.reelsCards.length]);

    // Otomatik slider işlevi
    useEffect(() => {
        const autoSlide = () => {
            if (sliderRef.current) {
                const totalScrollLeft = sliderRef.current.scrollLeft + cardWidth;

                // Eğer kopyaların sonuna geldiysek, başa sıfırlıyoruz
                if (sliderRef.current.scrollLeft >= sliderRef.current.scrollWidth / 2) {
                    sliderRef.current.scrollLeft = 0; // Başa dön
                } else {
                    sliderRef.current.scrollTo({
                        left: totalScrollLeft,
                        behavior: 'smooth', // Geçişin animasyonlu olması için
                    });
                }

                setActiveIndex((prevIndex) => (prevIndex + 1) % componentData.reelsCards.length); // Sonsuz döngüde kalması için modül operatörü kullanıyoruz
            }
        };

        if (!isPaused) {
            const interval = setInterval(autoSlide, 3000); // 3 saniyede bir geçiş yapılıyor
            return () => clearInterval(interval); // Temizleme işlemi
        }
    }, [isPaused, activeIndex, componentData.reelsCards.length]);

    const isVideo = (media: string) => {
        const videoExtensions = ['mp4', 'webm', 'ogg'];
        const extension = media.split('.').pop();
        return extension && videoExtensions.includes(extension.toLowerCase());
    };

    const closeModal = () => {
        setSelectedCard(null);
    };

    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    const handleMouseDown = () => {
        setIsPaused(true);
    };

    const handleMouseUp = () => {
        setIsPaused(false);
    };

    return (
        <div className="relative w-full flex justify-center items-center">
            <div
                ref={combineRefs(inViewRef, sliderRef)} // Ref birleştirici kullanılıyor
                className="max-w-[95%] overflow-x-scroll flex space-x-5 py-4 px-2 scrollbar-hide"
                style={{
                    scrollSnapType: 'x mandatory',
                    WebkitOverflowScrolling: 'touch',
                }}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                {duplicatedReelsCards.map((card, index) => (
                    <div
                        key={index}
                        className={`relative flex-shrink-0 w-[320px] h-[600px] rounded-xl shadow-lg overflow-hidden transition-transform transform-gpu hover:scale-105 duration-500 ${index === activeIndex ? 'active' : ''}`}
                        style={{ scrollSnapAlign: 'start' }}
                        onClick={() => setSelectedCard(card)}
                    >
                        {isVideo(card.media) ? (
                            <video
                                src={card.media}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <img
                                src={card.media}
                                alt={`Reels Card ${index + 1}`}
                                className="w-full h-full object-cover"
                            />
                        )}
                        <div className="absolute bottom-0 left-0 w-full p-6 bg-gradient-to-t from-black to-transparent text-white">
                            <h3 className="text-2xl text-center font-semibold mb-1" style={{ fontFamily: 'Radhanji, sans-serif' }}>
                                {card.title}
                            </h3>
                            <p className="text-gray-300 text-center mb-5" style={{ fontFamily: 'Radhanji, sans-serif' }}>
                                {card.subtitle}
                            </p>
                            {card.exploreUrl && (
                                <div className="text-center">
                                    <a
                                        href={card.exploreUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-block px-8 py-3 text-lg font-bold text-white mb-3 border-2 border-white rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg"
                                        style={{ fontFamily: 'Radhanji, sans-serif' }}
                                    >
                                        {card.buttonText || 'Keşfet'}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {selectedCard && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={handleBackgroundClick}
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                    <div className="relative w-[85vw] h-[70vh] rounded-lg overflow-hidden">
                        <button
                            className="absolute top-4 right-4 bg-gray-200 bg-opacity-30 p-2 rounded-full hover:bg-opacity-50 transition z-50"
                            onClick={closeModal}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {isVideo(selectedCard.media) ? (
                            <video src={selectedCard.media} className="w-full h-full object-cover" controls autoPlay />
                        ) : (
                            <img src={selectedCard.media} alt="Selected Media" className="w-full h-full object-cover" />
                        )}
                        {selectedCard.exploreUrl && (
                            <div className="absolute bottom-4 left-0 w-full text-center">
                                <a
                                    href={selectedCard.exploreUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block px-8 py-3 text-lg font-bold text-white mb-3 border-2 border-white rounded-full transition-all duration-500 hover:bg-white hover:bg-opacity-25 hover:text-black hover:border-gray-400 hover:shadow-lg"
                                    style={{ fontFamily: 'Radhanji, sans-serif' }}
                                >
                                    {selectedCard.buttonText || 'Keşfet'}
                                </a>
                            </div>
                        )}
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default ReelsCardSliderWithExploreSection;
