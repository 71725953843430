import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface LargeCardSectionProps {
    componentData: {
        media: string;   // Görsel, video, ses veya PDF URL'si
        url: string;     // Tıklanacak URL
        componentName: string;
    };
}

const LargeCardSection: React.FC<LargeCardSectionProps> = ({ componentData }) => {
    const { media, url } = componentData;
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor

    const { ref, inView } = useInView({
        triggerOnce: true, // Animasyon yalnızca bir kez tetiklenir
        threshold: 0.3, // %30'u görünür olduğunda tetiklenir
    });

    useEffect(() => {
        if (inView) {
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,
                visitorId: sessionStorage.getItem('visitorId'),
                componentName: componentData.componentName,
                componentCount: 1,
            })
            .then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            })
            .catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl]);

    const defaultMedia = 'https://via.placeholder.com/1920x1080?text=No+Media+Available';

    const renderMedia = (mediaUrl: string) => {
        const mediaType = mediaUrl.split('.').pop()?.toLowerCase();

        if (!mediaType) {
            return <p>Desteklenmeyen medya türü</p>;
        }

        // Video dosyaları için mobil uyumlu yükseklik
        if (['mp4', 'webm', 'ogg'].includes(mediaType)) {
            return (
                <motion.video
                    className="w-full h-[50vh] md:w-[95vw] md:h-[85vh] object-cover rounded-lg mx-auto" // Mobilde daha küçük yükseklik ayarlandı
                    autoPlay
                    muted
                    loop
                    playsInline
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4, ease: 'easeInOut' }}
                >
                    <source src={mediaUrl} type={`video/${mediaType}`} />
                    Tarayıcınız bu videoyu desteklemiyor.
                </motion.video>
            );
        }

        // Resim dosyaları için mobil uyumlu yükseklik
        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(mediaType)) {
            return (
                <motion.img
                    src={mediaUrl}
                    alt="Media"
                    className="w-full h-[50vh] md:w-[95vw] md:h-[85vh] object-cover rounded-lg mx-auto" // Mobilde daha küçük yükseklik ayarlandı
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: 0.4, ease: 'easeInOut' }}
                />
            );
        }

        // PDF dosyaları için mobil uyumlu yükseklik
        if (mediaType === 'pdf') {
            return (
                <iframe
                    className="w-full h-[50vh] md:w-[95vw] md:h-[85vh] mx-auto" // Mobilde daha küçük yükseklik ayarlandı
                    src={mediaUrl}
                    title="PDF Preview"
                    style={{ border: 'none' }}
                />
            );
        }

        // Ses dosyaları için
        if (['mp3', 'wav', 'ogg'].includes(mediaType)) {
            return (
                <audio controls className="w-full">
                    <source src={mediaUrl} type={`audio/${mediaType}`} />
                    Tarayıcınız bu sesi desteklemiyor.
                </audio>
            );
        }

        // Diğer doküman türleri için
        if (['doc', 'docx', 'ppt', 'pptx'].includes(mediaType)) {
            return (
                <a href={mediaUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    {`Dosyayı görüntüle: ${mediaUrl}`}
                </a>
            );
        }

        return <p>Desteklenmeyen medya türü</p>;
    };

    return (
        <motion.div
            ref={ref}
            className="p-5 bg-white rounded-3xl mb-8 flex justify-center w-full md:w-[85vw] h-[50vh] md:h-[70vh] mx-auto" // Mobil için w-full ve h-[50vh] ayarlandı
            initial={{ opacity: 0, y: 100 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1.2, ease: 'easeOut' }}
        >
            <div className="mb-4 w-full h-full">
                <motion.a
                    href={url || '#'}
                    target={url ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.05, rotate: 1 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                >
                    {renderMedia(media || defaultMedia)}
                </motion.a>
            </div>
        </motion.div>
    );
};

export default LargeCardSection;
