import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

interface CTACardSectionProps {
    title: string;
    subtitle: string;
    buttonText: string;
    buttonUrl: string;
    componentName: string;
}

const CTACardSection: React.FC<CTACardSectionProps> = ({ title, subtitle, buttonText, buttonUrl, componentName }) => {
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    useEffect(() => {
        if (inView) {
            // Backend'e post isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  // URL'den alınan launchName kullanılıyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentName: componentName,
                componentCount: 1, // Component sayısını burada belirtebilirsiniz
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl]);

    return (
        <motion.div
            ref={ref}
            className="flex flex-col md:flex-row justify-between items-center p-6 bg-white border border-gray-300 rounded-3xl shadow-lg mx-auto min-h-[190px] w-full md:w-[85vw]" // Mobilde dikey hizalama ve tam genişlik
            style={{ marginTop: '0px', fontFamily: 'Radhanji, sans-serif' }} // Font Radhanji olarak ayarlandı
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: 'easeOut' }}
        >
            <div className="flex flex-col ml-0 md:ml-8 text-center md:text-left"> {/* Mobilde merkezlenmiş, geniş ekranda sola hizalanmış */}
                <h3 className="text-2xl md:text-4xl font-semibold text-black">{title}</h3> {/* Mobilde daha küçük yazı boyutu */}
                {subtitle && <p className="text-xl md:text-3xl text-gray-600 mt-2">{subtitle}</p>} {/* Mobilde daha küçük yazı boyutu */}
            </div>

            <motion.a
                href={buttonUrl}
                className="mt-4 md:mt-0 md:mr-12 px-8 md:px-16 py-4 md:py-6 text-white rounded-full font-extrabold text-base md:text-lg transition-transform duration-300" // Buton boyutları ve yazı boyutu mobilde küçültüldü
                target="_blank"
                rel="noopener noreferrer"
                style={{ backgroundColor: '#970928' }} // Görseldeki renk (Pantone 7427 C) butona uygulandı
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
            >
                {buttonText}
            </motion.a>
        </motion.div>
    );
};

export default CTACardSection;
