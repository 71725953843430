import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface BottomTextCardSectionProps {
    componentData: {
        text: string;
        media: string;
        componentName: string;
    };
}

const BottomTextCardSection: React.FC<BottomTextCardSectionProps> = ({ componentData }) => {
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3,
    });

    useEffect(() => {
        if (inView) {
            // Backend'e post isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,  // URL'den alınan launchName kullanılıyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentName: componentData.componentName,
                componentCount: 1, // Component sayısını burada belirtebilirsiniz
            }).then((response) => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch((error) => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, launchUrl]);

    const defaultImage = 'https://via.placeholder.com/1200x800?text=Anonim+Görsel';

    const renderMediaPreview = () => {
        const mediaUrl = componentData.media || defaultImage;
        const fileExtension = mediaUrl.split('.').pop()?.toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension || '')) {
            return <img src={mediaUrl} alt="Selected Media" className="w-full h-full object-cover" />;
        }

        if (['mp4', 'webm', 'ogg'].includes(fileExtension || '')) {
            return (
                <video className="w-full h-full object-cover" autoPlay muted loop playsInline>
                    <source src={mediaUrl} type={`video/${fileExtension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        }

        if (fileExtension === 'pdf') {
            return <iframe src={mediaUrl} className="w-full h-full object-cover" title="PDF Viewer"></iframe>;
        }

        if (['mp3', 'wav', 'ogg'].includes(fileExtension || '')) {
            return (
                <audio controls className="w-full h-full">
                    <source src={mediaUrl} type={`audio/${fileExtension}`} />
                    Your browser does not support the audio element.
                </audio>
            );
        }

        return <p>Önizleme desteklenmiyor.</p>;
    };

    return (
        <div
            className="p-0 shadow-none mx-auto overflow-hidden rounded-t-3xl w-full md:w-[85vw] h-auto md:h-[70vh]" // Mobilde tam genişlik ve otomatik yükseklik
        >
            <div className="flex flex-col justify-between items-center w-full h-full">
                {/* Medya Alanı */}
                <div
                    className="w-full h-[70%] md:h-[80%] flex justify-center items-center" // Mobilde yükseklik otomatik, geniş ekranlarda %80
                    ref={ref}
                    style={{
                        opacity: inView ? 1 : 0,
                        transform: inView ? 'translateY(0)' : 'translateY(80px)',
                        transition: 'transform 1s ease-in-out, opacity 1s ease-in-out',
                    }}
                >
                    {renderMediaPreview()}
                </div>

                {/* Text Alanı */}
                <div className="text-center py-4 w-[95%] border-t">
                    <p
                        className="text-lg md:text-3xl font-bold text-gray-900 leading-tight break-words" // Mobilde daha küçük yazı boyutu
                        style={{ fontFamily: 'Radhanji, sans-serif' }}
                    >
                        {componentData.text}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BottomTextCardSection;
