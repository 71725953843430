import React from 'react';
import { createRoot } from 'react-dom/client'; // createRoot'u ekledik
import './styles/index.css'; // Global stil dosyasını buraya ekleyin
import App from './App';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement); // createRoot kullanıyoruz
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
