import React, { useEffect, useRef } from 'react';

interface PopupConsentProps {
    isOpen: boolean;
    onClose: () => void;
    onAccept: () => void;  // Kabul edildiğinde çalışacak fonksiyon
    language: 'en' | 'tr'; // Dil seçeneği
}

const PopupConsent: React.FC<PopupConsentProps> = ({ isOpen, onClose, onAccept, language }) => {
    const popupRef = useRef<HTMLDivElement>(null);

    // Pencerenin dışına tıklanınca popup'ı kapatmak için
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const handleAccept = () => {
        onAccept();  // Checkbox'ı işaretle
        onClose();   // Popup'u kapat
    };

    // Dil seçeneklerine göre metinleri tanımlayın
    const texts = {
        en: {
            title: "CONSENT NOTICE",
            content: "With this consent form, I approve the sending of commercial electronic messages by Damise Bilişim Hizmetleri A.Ş. through email, call center, telephone, newsletter, automatic calls, WhatsApp, social media, and similar internet applications for marketing, promotion, advertising, customer satisfaction surveys, and similar purposes, as well as the storage of this information in the information system of service providers located within the country and/or abroad, in accordance with the Law No. 6563 on the Regulation of Electronic Commerce.",
            accept: "I Accept",
        },
        tr: {
            title: "AÇIK RIZA METNİ",
            content: "İşbu muvafakatname ile Damise Bilişim Hizmetleri A.Ş. tarafından; doğrudan veya dolaylı olarak pazarlama, promosyon, reklam, satış, müşteri memnuniyeti araştırmaları, anket çalışmaları, tanıtım, kutlama, temenni, onay alma ve benzeri amaçlarla elektronik posta, çağrı merkezi, telefon, haber bülteni, otomatik arama, WhatsApp, sosyal medya ve benzeri internet uygulamaları vasıtasıyla ticari elektronik iletiler gönderilmesine ve bu faaliyetlerin yürütülmesi amacıyla yurtiçi ve/veya yurtdışında bulunan hizmet sağlayıcı bilgi sisteminde muhafaza edilebilmesine 6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun uyarınca onay veriyorum.",
            accept: "Kabul Ediyorum",
        },
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div ref={popupRef} className="bg-white p-6 rounded-lg max-w-lg w-full relative max-h-[80vh] overflow-y-auto">
                <h2 className="text-xl font-semibold mb-4">{texts[language].title}</h2>
                <p className="mb-4">{texts[language].content}</p>

                {/* Butonu sağ alta hizalıyoruz */}
                <div className="flex justify-end mt-6">
                    <button
                        className="bg-white text-blue-500 border border-blue-500 px-6 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition duration-300"
                        onClick={handleAccept}  // Kabul edildiğinde handleAccept fonksiyonu çalışır
                    >
                        {texts[language].accept}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupConsent;
