import React, { useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface ReelsCardSliderSectionProps {
    componentData: {
        reelsCards: Array<{
            media: string;
            title: string;
            subtitle: string;
        }>;
        componentName: string; // Backend'e göndermek için componentName ekleniyor
    };
}

const ReelsCardSliderSection: React.FC<ReelsCardSliderSectionProps> = ({ componentData }) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const { launchUrl: urlParam } = useParams<{ launchUrl: string }>(); // URL'den launchUrl alınıyor
    const launchUrl = urlParam || 'homepage'; // Eğer launchUrl boşsa "homepage" olarak ayarlanıyor
    const [selectedMedia, setSelectedMedia] = useState<string | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const { ref: inViewRef, inView } = useInView({
        triggerOnce: true, // Sadece bir kez tetiklenmesini sağlıyor
        threshold: 0.3, // Görünürlük tetikleyici eşiği
    });

    const cardWidth = 320; // Kart genişliği

    // Ref'leri birleştiren bir fonksiyon
    const combineRefs = (...refs: any[]) => (node: any) => {
        refs.forEach(ref => {
            if (typeof ref === 'function') {
                ref(node);
            } else if (ref) {
                ref.current = node;
            }
        });
    };

    // Sonsuz kaydırma için kartları kopyalıyoruz
    const duplicatedCards = [...componentData.reelsCards, ...componentData.reelsCards];

    useEffect(() => {
        if (inView) {
            // Backend'e POST isteği gönderiyoruz
            axios.post(`${process.env.REACT_APP_API_URL}/launch-component`, {
                launchUrl,
                componentName: componentData.componentName,  // Bileşen adı backend'e gönderiliyor
                visitorId: sessionStorage.getItem('visitorId'),  // sessionStorage'dan visitorId alınıyor
                componentCount: componentData.reelsCards.length,  // Kart sayısını backend'e gönderiyoruz
            }).then(response => {
                console.log('Component verisi başarıyla kaydedildi:', response.data);
            }).catch(error => {
                console.error('Component verisi kaydedilirken hata oluştu:', error);
            });
        }
    }, [inView, componentData.componentName, componentData.reelsCards.length]);

    // Otomatik kaydırma işlevi
    useEffect(() => {
        const autoSlide = () => {
            if (sliderRef.current) {
                const maxScrollLeft = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;

                if (sliderRef.current.scrollLeft >= maxScrollLeft) {
                    // Son kartlara ulaştık, kopyalara geçiyoruz
                    sliderRef.current.scrollTo({
                        left: 0, // En başa gitmek yerine, kopyalara yöneliyoruz
                        behavior: 'smooth',
                    });
                    setActiveIndex(0);
                } else {
                    sliderRef.current.scrollBy({
                        left: cardWidth,
                        behavior: 'smooth',
                    });
                    setActiveIndex((prevIndex) => prevIndex + 1);
                }
            }
        };

        if (!isPaused) {
            const interval = setInterval(autoSlide, 3000); // 3 saniyede bir kaydırma işlemi
            return () => clearInterval(interval); // Interval'i temizleme
        }
    }, [isPaused, activeIndex, componentData.reelsCards.length]);

    const isVideo = (media: string) => {
        const videoExtensions = ['mp4', 'webm', 'ogg'];
        const extension = media.split('.').pop();
        return extension && videoExtensions.includes(extension.toLowerCase());
    };

    const closeModal = () => {
        setSelectedMedia(null);
    };

    const handleBackgroundClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    return (
        <div className="relative w-full flex justify-center items-center">
            {/* Slider */}
            <div
                ref={combineRefs(inViewRef, sliderRef)} // İki ref birleştirildi
                className="max-w-[95%] overflow-x-scroll flex space-x-5 py-4 px-2 scrollbar-hide"
                style={{
                    scrollSnapType: 'x mandatory',
                    WebkitOverflowScrolling: 'touch',
                }}
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => setIsPaused(false)}
            >
                {duplicatedCards.map((card, index) => (
                    <div
                        key={index}
                        className="relative flex-shrink-0 w-[320px] h-[600px] rounded-3xl shadow-lg overflow-hidden transition-transform transform-gpu hover:scale-105 duration-500"
                        style={{ scrollSnapAlign: 'start' }}
                        onClick={() => setSelectedMedia(card.media)}
                    >
                        {isVideo(card.media) ? (
                            <video
                                src={card.media}
                                className="w-full h-full object-cover"
                                autoPlay
                                muted
                                loop
                            />
                        ) : (
                            <img
                                src={card.media}
                                alt={`Reels Card ${index + 1}`}
                                className="w-full h-full object-cover"
                            />
                        )}

                        <div className="absolute bottom-0 left-0 w-full p-4 bg-gradient-to-t from-black to-transparent text-white">
                            <div className="p-4">
                                <h3
                                    className="text-2xl text-center font-semibold mb-1"
                                    style={{ fontFamily: 'Radhanji, sans-serif' }}
                                >
                                    {card.title}
                                </h3>
                                <p
                                    className="text-gray-300 text-center"
                                    style={{ fontFamily: 'Radhanji, sans-serif' }}
                                >
                                    {card.subtitle}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pop-up Modal */}
            {selectedMedia && (
                <motion.div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={handleBackgroundClick}
                    initial={{ scale: 0.5, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                    <div className="relative w-[85vw] h-[70vh] rounded-lg overflow-hidden"> {/* Pop-up ölçüsü belirlendi */}
                        <button
                            className="absolute top-2 right-2 bg-gray-200 bg-opacity-30 p-2 hover:bg-opacity-50 transition z-50"
                            onClick={closeModal}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        {isVideo(selectedMedia) ? (
                            <video
                                src={selectedMedia}
                                className="w-full h-full object-cover"
                                controls
                                autoPlay
                            />
                        ) : (
                            <img
                                src={selectedMedia}
                                alt="Selected Media"
                                className="w-full h-full object-cover" // Görsel pop-up alanını dolduracak şekilde ayarlandı
                            />
                        )}
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default ReelsCardSliderSection;
